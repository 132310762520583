import { Stack } from "@mui/material";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

import InputDate from "./inputs/InputDate";
import MultiSelectDropdown from "./inputs/MultiSelectDropdown";
import Dropdown from "./inputs/Dropdown";
import DropNText from "./inputs/DropNText";
import { IInputFiltersProps } from "./types";
import { IOptionData } from "./inputs/types";

interface IFilterData {
  filterLayout: any[];
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  state?: any;
}

export const filterData = ({ filterLayout, getValues, setValue, state }: IFilterData): any[] => {
  let filterArr = filterLayout.map((e) => {
    const { type, formKey, optionData ,searchKey, searchText} = e;
    if (type === "DropNText") {
      return {
        ...e,
        handleOptionChange: (val: IOptionData[]) =>
          setValue(
            searchKey?? "searchKeys",
            val.map(({ value }) => value)
          ),
        handleTextChange: (val: string) => {
          if (!getValues(searchKey??"searchKeys")) {
            setValue(
              searchKey?? "searchKeys",
              optionData.map(({ value }: IOptionData) => value)
            );
          }
          setValue(searchText??"searchText", val);
        },
      };
    }

    if (type === "Dropdown") {
      return { ...e, handleValueChange: (val: IOptionData) => setValue(formKey, val.value) };
    }

    return { ...e, handleChange: (val: any) => setValue(formKey, val) };
  });

  if (state) {
    let arr = Object.keys(state);

    arr.forEach((e) => {
      const index = filterArr.findIndex(({ formKey }) => formKey === e);

      if (index >= 0) {
        filterArr[index] = {
          ...filterArr[index],
          preSelectedValue: filterArr[index].optionData?.find(({ value }: { value: string }) => value === state[e]),
        };
      }
    });
  }

  return filterArr;
};

const InputSwitch = (e, getValues) => {
  const { label, type, optionData, searchKey, searchText } = e;

  switch (type) {
    case "DropNText":
      let val = getValues(searchKey ?? "searchKeys");
      let option = optionData.find(({ value }) => val?.length === 1 && value === val?.[0]);
      let valueKey = option?.label ? option : { value: "All", label: "All" };

      return (
        <DropNText
          {...e}
          key={label}
          valueText={getValues(searchText ?? "searchText")}
          valueKey={valueKey}
          showAll={false}
        />
      );

    case "InputDate":
      return <InputDate {...e} key={label} />;

    case "MultiSelectDropdown":
      return <MultiSelectDropdown {...e} key={label} />;

    case "Dropdown":
      return <Dropdown {...e} key={label} />;

    default:
      return <></>;
  }
};

const InputFilters: React.FC<IInputFiltersProps> = ({ filter, getValues, customStyle, component = "form" }) => {
  return (
    <Stack direction="row" flexWrap="wrap" p="20px" columnGap={2} rowGap={3} sx={customStyle} component={component}>
      {filter.map((e) => InputSwitch(e, getValues))}
    </Stack>
  );
};

export default InputFilters;

import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
  Switch,
  SwitchProps,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { getApi, postApi } from "../../service";
import { useConfig } from "../../providers/ConfigProvider";
import { configurationReview } from "./helper";
import { useUser } from "../../providers/UserProvider";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 28,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0C8CAA",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "8px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
    margin: 2,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16,
    backgroundColor: theme.palette.mode === "light" ? "#D8D8D8" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface ICommonConfigurationProps {
  categories: { key: string; name: string }[];
  tab: string;
}
interface IRowData {
  category: string;
  disabled: boolean;
  id: number;
  key: string;
  label: string;
  type: string;
  isInactive?: boolean;
}

const CommonConfiguration: React.FC<ICommonConfigurationProps> = ({ categories, tab }) => {
  const { configState, setConfigState } = useConfig();
  const [expand, setExpand] = useState<boolean[]>([]);
  const [tempValue, setTempValue] = useState(0);
  const [homeViewDisabledCount, setHomeViewDisabledCount] = useState(0);
  const [insightsViewDisabledCount, setInsightsViewDisabledCount] = useState(0);
  const [, setUpdateScreen] = useState(0);
  const { user } = useUser();

  const requestBody = {
    userId: user.email,
    countries: user.countries || [],
    org: process.env.REACT_APP_ORG,
    sector: process.env.REACT_APP_SECTOR,
  };

  const getUpdateRequestBody = (row, isDisabled) => {
    return {
      ...requestBody,
      api_name: "UpdateUserInsight",
      countries: user.countries || [],
      country: user.countries ? user.countries : "India",
      data: {
        insightId: row.id,
        category: row.category,
        description: row.description,
        label: row.label,
        disabled: !isDisabled,
      },
    };
  };

  useEffect(() => {
    if (tab === "insights") {
      (async () => {
        const payload = await postApi(`${process.env.REACT_APP_POST}`, {
          api_name: "GetUserInsightMapping",
          ...requestBody,
        });
        setConfigState(configurationReview(payload.data.data));
        setTempValue((val) => val + 1);
      })();
    } else {
      (async () => {
        const payload = await getApi(`${process.env.REACT_APP_URL}/configurations`);
        setConfigState(configurationReview(payload.data));
      })();
    }
  }, []);

  useEffect(() => {
    (() => {
      setHomeViewDisabledCount(0);
      setInsightsViewDisabledCount(0);
      configState &&
        configState.insights &&
        configState.insights.homeView &&
        configState?.insights?.homeView.map((ele) => {
          if (!ele.disabled) {
            setHomeViewDisabledCount((val) => val + 1);
          }
        });
      configState &&
        configState.insights &&
        configState.insights.insightView &&
        configState?.insights?.insightView.map((ele) => {
          if (!ele.disabled) {
            setInsightsViewDisabledCount((val) => val + 1);
          }
        });
      if (tab === "insights") {
        if (homeViewDisabledCount === 3) {
          configState?.insights?.homeView?.map((row) => {
            if (row.disabled) {
              row.isInactive = true;
              setUpdateScreen((prevVal) => prevVal + 1);
            }
          });
        } else if (homeViewDisabledCount < 3) {
          configState?.insights?.homeView?.map((row) => {
            row.isInactive = false;
            setUpdateScreen((prevVal) => prevVal + 1);
          });
        }
        if (insightsViewDisabledCount === 5) {
          configState?.insights?.insightView?.map((row) => {
            if (row.disabled) {
              row.isInactive = true;
              setUpdateScreen((prevVal) => prevVal + 1);
            }
          });
        } else if (insightsViewDisabledCount < 5) {
          configState?.insights?.insightView?.map((row) => {
            row.isInactive = false;
            setUpdateScreen((prevVal) => prevVal + 1);
          });
        }
      }
    })();
  }, [tempValue, homeViewDisabledCount, insightsViewDisabledCount]);

  const handleChange = async (row: IRowData, index: number, isDisabled: boolean) => {
    if (tab === "insights") {
      const updateRequestBody = getUpdateRequestBody(row, isDisabled);
      const response = await postApi(`${process.env.REACT_APP_POST}`, updateRequestBody);
      if (response.data.status) {
        setConfigState({
          ...configState,
          [tab]: {
            ...configState[tab],
            [row.category]: [
              ...configState[tab][row.category].slice(0, index),
              { ...configState[tab][row.category][index], disabled: !isDisabled },
              ...configState[tab][row.category].slice(index + 1),
            ],
          },
        });
      }
      const requestBody = {
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        userId: user.email,
        api_name: "TriggerUserUpdateInsights",
        countries: user.countries || [],
        soldTo: user.soldTo,
      };
      await postApi(`${process.env.REACT_APP_POST}`, requestBody);
      setTempValue((val) => val + 1);
    } else if (tab !== "insights") {
      setConfigState({
        ...configState,
        [tab]: {
          ...configState[tab],
          [row.category]: [
            ...configState[tab][row.category].slice(0, index),
            { ...configState[tab][row.category][index], disabled: !isDisabled },
            ...configState[tab][row.category].slice(index + 1),
          ],
        },
      });
      // await postApi(`${process.env.REACT_APP_URL}/configurations`, { ...row, disabled: !isDisabled });
    }
  };

  return (
    <Stack>
      <Typography variant="h1" fontSize={"18px"} letterSpacing="0.36px">
        Editable fields
      </Typography>

      <Typography variant="h3" sx={{ my: "16px" }}>
        {tab === "insights"
          ? "Turn on the toggle to make the Insight available for JJCC user."
          : "Toggle the fields ‘on’ to make them editable for JJCC users."}
      </Typography>

      <FormControl component="fieldset" variant="standard">
        {categories.map(({ name, key }, index: number) => {
          return (
            <FormGroup
              key={key}
              sx={{ pl: 1, border: "1px solid #D8D8D8", width: "642px", borderRadius: "8px", my: "8px" }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0 8px 0 8px",
                }}
              >
                <Typography variant="h1" fontSize={"18px"} letterSpacing="0.36px" sx={{ my: "16px" }}>
                  {name}
                </Typography>
                {expand[index] ? (
                  <ExpandLess
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      const arr: boolean[] = [...expand];
                      arr[index] = false;
                      setExpand(arr);
                    }}
                  />
                ) : (
                  <ExpandMore
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      const arr: boolean[] = [...expand];
                      arr[index] = true;
                      setExpand(arr);
                    }}
                  />
                )}
              </span>

              {expand[index] &&
                configState[tab] &&
                configState[tab][key] &&
                configState[tab][key].map((row: IRowData, index: number) => (
                  <FormComponent
                    row={row}
                    uniqueKey={key}
                    index={index}
                    key={row.key}
                    tab={tab}
                    handleChange={() => handleChange(row, index, row.disabled)}
                    isChecked={!row.disabled}
                    isInactive={row?.isInactive}
                  />
                ))}
            </FormGroup>
          );
        })}
      </FormControl>
    </Stack>
  );
};

interface IFormComponentProps {
  row: IRowData;
  uniqueKey?: string;
  index?: number;
  tab?: string;
  handleChange: () => void;
  isChecked: boolean;
  isInactive?: boolean;
}

const FormComponent: React.FC<IFormComponentProps> = ({ row, handleChange, isChecked, isInactive = false }) => {
  return (
    <FormControlLabel
      sx={{ mb: "14px", px: 2 }}
      control={
        <IOSSwitch
          key={row.key}
          sx={{ width: "48px", height: "28px", mr: 2 }}
          checked={isChecked}
          onChange={handleChange}
          name={row.key}
          disabled={isInactive}
        />
      }
      label={row.label}
    />
  );
};

export default CommonConfiguration;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Table from "../components/common/table/Table";
import DelimitBox from "../components/common/DelimitBox";
import BasicHeader from "../components/common/BasicHeader";
import InputFilters, { filterData } from "../components/common/InputFilters";
import { ITableData, ITableRowFrame } from "../components/common/table/types";
import { postApi } from "../service";
import { useUser } from "../providers/UserProvider";
import { createTableSearchObject } from "../components/transactions/handler";
import NavigateBack from "../components/navbar/NavigateBack";
import { tableLayoutJjcc } from "../components/transactions/constants";
import { ISelfServiceProps } from "../components/transactions/types";
import { countryDetails } from "../components/common/constants/countries";

const JJCCTransactions = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const requestData = {
    api_name: "DetailList",
    index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-inbound-order-details`,
    countries: user.countries || [],
    page: 1,
    size: 10,
    org: process.env.REACT_APP_ORG,
    sector: process.env.REACT_APP_SECTOR,
  };
  const tableLayout = tableLayoutJjcc(t);
  if (user.countries && user.countries.length > 1)
    tableLayout.filter.push({
      type: "MultiSelectDropdown",
      label: "Select Country",
      placeholder: "Select Country",
      optionData: user.countries.map((item) => {
        return {
          value: item,
          label: countryDetails[item].name,
        };
      }),
      customStyle: { width: "260px" },
      formKey: "countries",
    });
  const { arr, filter, header }: ISelfServiceProps = tableLayoutJjcc(t);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { getValues, setValue, control, reset } = useForm();
  const watchForm = useWatch({ control });

  const [transactionData, setTransactionData] = useState<ITableData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [flexibleTableColumn, setFlexibleTableColumn] = useState<ITableRowFrame[]>([]);
  const [newFilter, setNewFilter] = useState<any[]>([]);

  useEffect(() => {
    setFlexibleTableColumn(
      arr.map((e) => {
        if (e.name === "gatewayOrderNumber") {
          return {
            ...e,
            onClick: (id: any) =>
              navigate(`/self-service/jjcc/details/gatewayOrderNumber?gatewayOrderNumber=${id.gatewayOrderNumber}`),
          };
        }

        return e;
      })
    );

    let filterArr = filterData({ filterLayout: filter, setValue, getValues });

    setNewFilter(filterArr);
  }, []);

  const handleClearInputs = () => window.location.reload();

  const handleSearch = () => {
    setIsLoading(true);
    const timeOutId = setTimeout(async () => {
      const res = await postApi(`${process.env.REACT_APP_POST}`, {
        ...requestData,
        ...createTableSearchObject(watchForm),
      });
      setIsLoading(false);
      setTransactionData(res.data.data.map((e) => ({ ...e, ...e._source })));
    }, 500);

    return () => clearTimeout(timeOutId);
  };

  useEffect(() => {
    reset();
    setIsLoading(true);
    const timeOutId = setTimeout(async () => {
      let res = await postApi(`${process.env.REACT_APP_POST}`, requestData);

      setIsLoading(false);
      setTransactionData(
        res.data.data.map(({ _source, ...rest }) => ({
          ...rest,
          ..._source,
          orderStatus: _source.orderStatus?.toUpperCase() === "START" || "SUCCESS" ? "SUCCESS" : _source.orderStatus,
        }))
      );
    }, 500);

    return () => clearTimeout(timeOutId);
  }, [pathname]);

  return (
    <Container maxWidth="xl">
      <NavigateBack headerText="SELF SERVICE" />

      <DelimitBox customStyle={{ p: 0, mt: 2 }}>
        <BasicHeader title={header} customStyle={{ p: "20px" }} />
        <InputFilters filter={newFilter} getValues={getValues} />
        <Button variant="contained" sx={{ ml: 3, mb: 2, width: "10vw", fontSize: "16px" }} onClick={handleSearch}>
          Search
        </Button>
        <Button variant="outlined" sx={{ ml: 3, mb: 2, width: "10vw", fontSize: "16px" }} onClick={handleClearInputs}>
          Reset
        </Button>
        {isLoading ? (
          <Skeleton variant="rounded" width={"100%"} height={360} sx={{ mb: 2 }} />
        ) : transactionData && transactionData.length < 1 ? (
          <Typography align="center" sx={{ pt: 10, pb: 10 }}>
            No data to display
          </Typography>
        ) : (
          <Table
            transactionData={transactionData}
            isViewMoreBtnExist={false}
            flexibleTableColumn={flexibleTableColumn}
            setFlexibleTableColumn={setFlexibleTableColumn}
          />
        )}
      </DelimitBox>
    </Container>
  );
};

export default JJCCTransactions;

import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ViewStreamOutlined } from "@mui/icons-material";

import DelimitBox from "../../common/DelimitBox";

const AnnouncementCard = () => {
  const theme = useTheme();

  return (
    <DelimitBox customStyle={{ height: "100%" }}>
      <Typography
        variant="h1"
        sx={{
          position: "relative",
          mt: 3,
          "&:before": {
            content: `""`,
            height: 40,
            width: 6,
            top: -6,
            left: -20,
            position: "absolute",
            bgcolor: theme.palette.primary.main,
          },
        }}
      >
        Maintenance announcement
      </Typography>
      <Box
        sx={{
          position: "relative",
          my: 3,
          width: 1,
          height: "1px",
          p: "1px",
          background: `linear-gradient(90deg, ${theme.palette.primary.main} 16%, rgba(255,255,255,0) 100%)`,
        }}
      />
      <Typography>Coming soon...</Typography>
    </DelimitBox>
  );
};

export default AnnouncementCard;

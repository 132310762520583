/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { Box, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import ProductCard from "./ProductCard";
import AnnouncementCard from "./AnnouncementCard";

const ProductList = ({ products }) => {
  const navigate = useNavigate();
  const [interfaceCounts, setInterfaceCounts] = useState([
    { name: "Total", value: 0 },
    { name: "Real Time(Sync)", value: 0 },
    { name: "Async", value: 0 },
  ]);
  useEffect(() => {
    if (products.length > 0) {
      let total = 0;
      let sync = 0;
      products.forEach((item) => {
        total += item.noOfInterfaces;
        sync += item.syncInterfaces;
      });
      setInterfaceCounts([
        { name: "Total", value: total },
        { name: "Real Time(Sync)", value: sync },
        { name: "Async", value: total - sync },
      ]);
    }
  }, [products]);

  return (
    <Container maxWidth="xl">
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="h1" sx={{ my: 2 }}>
            Choose a platform to explore
          </Typography>
          <Typography variant="body1">See the various resources that each platform has to offer</Typography>
        </Box>
        <Stack direction="row">
          {interfaceCounts.map(({ name, value }, i) => {
            return (
              <Fragment key={i}>
                {i ? <Divider orientation="vertical" variant="middle" flexItem /> : <></>}
                <Box sx={{ margin: "auto", px: 5 }}>
                  <Typography textAlign="center">{name}</Typography>
                  <Typography variant="h1" textAlign="center">
                    {value}
                  </Typography>
                </Box>
              </Fragment>
            );
          })}
        </Stack>
      </Stack>

      <Box p={3}>
        <Grid container justifyContent="left" spacing={4}>
          <Grid item xs={12} sm={8.5}>
            <Grid item container spacing={4}>
              {products.map(({ name, description, noOfInterfaces }) => {
                return (
                  <Grid
                    key={name}
                    item
                    onClick={() => navigate(`/catalog/overview/${name}`)}
                    sx={{ cursor: "pointer" }}
                    xs={12}
                    sm={4}
                  >
                    <ProductCard name={name} description={description} interfaceCount={noOfInterfaces} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <AnnouncementCard />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ProductList;

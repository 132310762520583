import React from "react";
import { Box, Divider } from "@mui/material";

import { IServiceList } from "../transactions/types";
import { ViewTypeEnum } from "../common/inputs/types";
import InputDate from "../common/inputs/InputDate";
import InputTextField from "../common/inputs/InputTextField";
import Dropdown from "../common/inputs/Dropdown";
import { useForm } from "../../providers/FormProvider";

interface IOrderDetailsFormProps {
  transactionServiceData: IServiceList[][];
  setTransactionServiceData: React.Dispatch<React.SetStateAction<IServiceList[][]>>;
}

const OrderDetailsForm: React.FC<IOrderDetailsFormProps> = ({ transactionServiceData, setTransactionServiceData }) => {
  const { isEditable } = useForm();

  return (
    <>
      {transactionServiceData.map((arr, i) => {
        return (
          <React.Fragment key={i}>
            {i ? <Divider sx={{ borderWidth: "1px" }} /> : <></>}
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {arr.map(
                ({ name, type, label, value, width = 4, breakk, placeholder, disabled, lists, valueKey }, index) => {
                  const viewType = isEditable
                    ? disabled
                      ? ViewTypeEnum.EDITABLE
                      : ViewTypeEnum.DISABLED
                    : ViewTypeEnum.READABLE;

                  return (
                    <Box sx={{ flex: `0 0 ${100 / (16 / (breakk ?? width))}%`, padding: "0px 8px" }} key={index}>
                      {type === "date" ? (
                        <InputDate
                          label={label ?? ""}
                          placeholder={"YYYY-MM-DD"}
                          date={value}
                          viewType={viewType}
                          disabled={disabled}
                          handleChange={(val) => {
                            setTransactionServiceData([
                              ...transactionServiceData.slice(0, i),
                              [
                                ...transactionServiceData[i].slice(0, index),
                                {
                                  ...transactionServiceData[i][index],
                                  value: val,
                                },
                                ...transactionServiceData[i].slice(index + 1),
                              ],
                              ...transactionServiceData.slice(i + 1),
                            ]);
                          }}
                          customStyle={{
                            width: breakk ? `${100 / (16 / width)}%` : "100%",
                            pb: 2,
                            pt: 1,
                          }}
                        />
                      ) : type === "dropdown" ? (
                        <Dropdown
                          label={label}
                          viewType={viewType}
                          optionData={lists ?? []}
                          disabled={disabled}
                          preSelectedValue={value && lists?.length ? lists.find((ele) => value === ele.value) : {}}
                          handleValueChange={(val) =>
                            setTransactionServiceData([
                              ...transactionServiceData.slice(0, i),
                              [
                                ...transactionServiceData[i].slice(0, index),
                                {
                                  ...transactionServiceData[i][index],
                                  value: val.value,
                                },
                                ...transactionServiceData[i].slice(index + 1),
                              ],
                              ...transactionServiceData.slice(i + 1),
                            ])
                          }
                          customStyle={{
                            width: breakk ? `${100 / (16 / width)}%` : "100%",
                            pb: 2,
                            pt: 1,
                          }}
                        />
                      ) : (
                        <InputTextField
                          name={name}
                          viewType={viewType}
                          label={label}
                          defaultValue={value}
                          disabled={disabled}
                          placeholder={placeholder}
                          handleValueChange={(val) => {
                            setTransactionServiceData([
                              ...transactionServiceData.slice(0, i),
                              [
                                ...transactionServiceData[i].slice(0, index),
                                {
                                  ...transactionServiceData[i][index],
                                  value: val,
                                },
                                ...transactionServiceData[i].slice(index + 1),
                              ],
                              ...transactionServiceData.slice(i + 1),
                            ]);
                          }}
                          customStyle={{
                            width: breakk ? `${100 / (16 / width)}%` : "100%",
                            pb: 2,
                            pt: 1,
                          }}
                        />
                      )}
                    </Box>
                  );
                }
              )}
            </Box>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default OrderDetailsForm;

import React, { useState } from "react";
import { Box, Drawer, IconButton, Link, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import CollapsibleTable from "./CollapsibleTable";
import { ITemporaryDrawerProps } from "../types";

const TemporaryDrawer: React.FC<ITemporaryDrawerProps> = ({ title, pastActionData }) => {
  const [open, setOpen] = useState<boolean>(false);

  const list = (
    <Box sx={{ width: "692px", margin: 0, padding: 0 }} role="presentation">
      <List sx={{ margin: 0, padding: 0 }} disablePadding={true}>
        <ListItem>
          <ListItemText
            sx={{ paddingLeft: "10px" }}
            primary={<Typography variant="h1">Past Actions on {title} details</Typography>}
          />
          <IconButton color="primary" onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </ListItem>
      </List>
      <CollapsibleTable pastActionData={pastActionData} />
    </Box>
  );

  return (
    <>
      <Link component="button" underline="always" onClick={() => setOpen(true)}>
        <Typography sx={{ flex: 1 }} variant="body2" align="left">
          See past actions on {title} details
        </Typography>
      </Link>
      <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
        {list}
      </Drawer>
    </>
  );
};

export default TemporaryDrawer;

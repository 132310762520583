import { Typography } from "@mui/material";

const Overview = ({ product }) => {
  return (
    <>
      <Typography variant="h1" sx={{ my: 2 }}>
        About
      </Typography>

      <Typography variant="body1">{product.aboutDescription}</Typography>

      <Typography variant="h1" sx={{ my: 2 }}>
        Capabilities :
      </Typography>
      <Typography variant="body1">{product.capabilities.title}</Typography>
      <ul>
        {product.capabilities.capList.map((e, i) => (
          <li key={i} style={{ margin: "10px 0" }}>
            {e}
          </li>
        ))}
      </ul>
    </>
  );
};

export default Overview;

import React, { createContext, useContext, useState } from "react";
import { IChildrenProps } from "../components/common/types";

export interface IEnvironmentContext {
  environmentState: string;
  setEnvironmentState: (environmentState: string) => void;
}

export const EnvironmentContext = createContext<IEnvironmentContext>({
  environmentState: "dev",
  setEnvironmentState: () => {},
});

export const useEnvironment = () => {
  const context = useContext(EnvironmentContext);
  if (context === undefined) {
    throw new Error("useEnvironment must be used within a EnvironmentProvider");
  }
  return context;
};

export const EnvironmentProvider: React.FC<IChildrenProps> = ({ children }) => {
  const [environmentState, setEnvironmentState] = useState< string>('dev');

  return <EnvironmentContext.Provider value={{ environmentState, setEnvironmentState }}>{children}</EnvironmentContext.Provider>;
};

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";

import BacktrackModal from "../common/modal/BacktrackModal";
import { useForm } from "../../providers/FormProvider";

interface INavigateBackProps {
  headerText: string;
}

const NavigateBack = ({ headerText }: INavigateBackProps) => {
  const navigate = useNavigate();
  const { isEditable } = useForm();

  const [open, setOpen] = useState<boolean>(false);

  const handleBack = () => (isEditable ? setOpen(true) : navigate(-1));

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <ChevronLeft sx={{ height: 34, cursor: "pointer" }} color="primary" onClick={handleBack} />
      <Typography variant="h1">{headerText}</Typography>

      <BacktrackModal open={open} setOpen={setOpen} />
    </Stack>
  );
};

export default NavigateBack;

import { ChartOptions } from "chart.js";
import dayjs from "dayjs";
import { TFunction } from "i18next";

export const initDetails = {
  range: "",
  totalCount: 0,
  successCount: 0,
  failureCount: 0,
  successRate: 0,
  failureRate: 0,
};
export const analyticsDetailsLabel = {
  totalCount: "Total Transactions",
  successCount: "Success Transactions",
  successRate: "Success Rate",
  failureCount: "Failed Transactions",
  failureRate: "Failed Rate",
};

// TEMP DATA
export const api = "http://localhost:3733/api/performance";
export const reqTimeObj = {
  org: "hmd-jjcc-nonprod-qa",
  sector: "medical",
  api_name: "PerformanceInsightForTransactionTime",
};
export const reqSuccessObj = {
  org: "hmd-jjcc-nonprod-qa",
  sector: "medical",
  api_name: "PerformanceInsightForSuccessAndFailure",
  duration: "1 Month",
};
export const reqErrorObj = {
  org: "hmd-jjcc-nonprod-qa",
  sector: "medical",
  api_name: "PerformanceInsightForErrorCodeAnalysis",
  duration: "1 Month",
};

export const interfaceOptions = [
  { interfaceValue: "Delivery Sync", interfaceName: "Delivery Sync" },
  { interfaceValue: "Order Sync", interfaceName: "Order Sync" },
];

// Time Chart Options
export const timeChartOption: ChartOptions<"line"> = {
  plugins: {
    tooltip: {
      enabled: true,
      mode: "nearest",
      callbacks: {
        title: (tooltipItems: any): string =>
          dayjs(tooltipItems[0].dataset.data[tooltipItems[0].dataIndex][tooltipItems[0].dataIndex].date).format(
            "DD.MM.YYYY"
          ),
        label: (tooltipItems: any): string => parseInt(tooltipItems.parsed.y) / 1000 + "s",
      },
    },
  },
  responsive: true,
  scales: {
    x: { grid: { display: false }, title: { display: true, text: "Duration" } },
    y: {
      grid: { display: false },
      title: { display: true, text: "Duration in mili seconds" },
      ticks: { callback: (value: any): string => parseInt(value) / 1000 + "s" },
    },
  },
};

// Success Chart Options
export const successChartOption: ChartOptions<"bar"> = {
  responsive: true,
  plugins: { legend: { display: false } },
  scales: {
    x: { grid: { display: false }, title: { display: true, text: "Duration" } },
    y: { grid: { display: false }, title: { display: true, text: "Number of Transactions" } },
  },
};

// Success Chart Options
export const cardChartOption: ChartOptions<"bar"> = {
  responsive: true,
  plugins: { legend: { display: false } },
  scales: { x: { display: false }, y: { display: false } },
};

// Error Chart Options
export const errorChartOption = (title: string): ChartOptions<"doughnut"> => {
  return {
    responsive: true,
    plugins: {
      legend: { position: "right", labels: { boxWidth: 14 } },
      title: {
        display: true,
        text: `Maximum Errors Identify with ${title}`,
        align: "start",
        position: "bottom",
        font: { size: 14 },
      },
    },
    elements: { arc: { borderWidth: 0, hoverOffset: 20 } },
    layout: { padding: { left: 20, right: 20 } },
  };
};

export const dateValues = (t: TFunction) => [
  { label: "One Week", value: "1 Week" },
  { label: t("ONE_MONTH"), value: "1 Month" },
  { label: t("THREE_MONTH"), value: "3 Month" },
  { label: t("SIX_MONTH"), value: "6 Month" },
];

import React from "react";
import { Box, SxProps } from "@mui/material";
import { IChildrenProps } from "./types";

interface IDelimitBox extends IChildrenProps {
  customStyle?: SxProps;
  onClick?: React.MouseEventHandler;
}

const DelimitBox: React.FC<IDelimitBox> = ({ children, customStyle = {}, onClick }) => {
  return (
    <Box
      sx={{
        bgcolor: "#fff",
        borderRadius: 2,
        p: "20px",
        boxShadow: "0px 4px 12px #00000033",
        ...customStyle,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default DelimitBox;

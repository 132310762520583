interface ICreateTableSearchParam {
  searchKeys?: string[];
  searchText?: string;
  fromOrderDate?: string;
  toOrderDate?: string;
  countries?: string[];
  status?: string;
  transactionType?: string;
}

// create API request data when transaction filter change
export const createTableSearchObject = (data: ICreateTableSearchParam) => {
  const { searchKeys, searchText, ...rest } = data;

  let obj = {};

  if (searchText && searchKeys) {
    obj = {
      key: searchKeys[0],
      value: searchText,
    };
  }

  return { ...obj, ...rest };
};

/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, ClickAwayListener, SxProps, Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { UseFormSetValue } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";

import { InputWrapper, Listbox, StyledTag } from "./styles";
import { postApi } from "../../service";
import { useUser } from "../../providers/UserProvider";

interface IFranchiseMultiSelect {
  setValue: UseFormSetValue<any>;
  customStyle?: SxProps;
  isClear?: boolean;
}

interface IOptionData {
  franchiseCode: string;
  franchiseName: string;
}

const FranchiseMultiSelect: React.FC<IFranchiseMultiSelect> = ({ setValue, customStyle = {}, isClear }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [selectedValues, setSelectedValues] = useState<IOptionData[]>([]);
  const [selectedValueObject, setSelectedValueObject] = useState<{ [key in string]: IOptionData }>({});
  const [options, setOptions] = useState<IOptionData[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [clearState, setClearState] = useState(isClear);

  const searchForFranchise = async (value) => {
    try {
      const franchiseDetailsByCode = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "GetAll",
        index_name: `${process.env.REACT_APP_ORG}-master-franchise`,
        key: "franchiseName",
        value,
        customSearch: [{ countryCode: user.countries[0] }],
      });

      const franchiseDetailsByName = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "GetAll",
        index_name: `${process.env.REACT_APP_ORG}-master-franchise`,
        key: "franchiseCode",
        value,
        customSearch: [{ countryCode: user.countries[0] }],
      });

      const { data: dataFetchedByName } = franchiseDetailsByName.data;
      const { data: dataFetchedByCode } = franchiseDetailsByCode.data;

      if (dataFetchedByName?.result?.length || dataFetchedByCode?.result?.length) {
        let result = [
          ...((dataFetchedByName?.result && dataFetchedByName?.result?.length && dataFetchedByName.result) || []),
          ...((dataFetchedByCode?.result && dataFetchedByCode?.result?.length && dataFetchedByCode.result) || []),
        ];

        setOptions(result.map(({ _source }) => _source));
      } else {
        setOptions([]);
      }
      return [];
    } catch (err) {
      console.error(err);
    }
  };

  const debounce = useDebouncedCallback(async (val) => await searchForFranchise(val), 500, { maxWait: 2000 });

  useEffect(() => {
    if (selectedValues) {
      setValue("franchises", selectedValues);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (inputValue) {
      debounce(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (clearState !== isClear) {
      setSelectedValues([]);
      setSelectedValueObject({});
      setClearState(!clearState);
      setInputValue("");
    }
  }, [isClear]);

  const onDelete = (option, index) => {
    const obj = { ...selectedValueObject };
    delete obj[option.franchiseCode];

    setSelectedValues([...selectedValues.slice(0, index), ...selectedValues.slice(index + 1)]);
    setSelectedValueObject(obj);
    setInputValue("");
  };

  const onSelect = ({ franchiseCode, franchiseName }) => {
    if (selectedValueObject?.[franchiseCode]) {
      onDelete(
        { franchiseCode, franchiseName },
        selectedValues.findIndex((e) => e.franchiseCode === franchiseCode)
      );
    } else {
      setSelectedValues([...selectedValues, { franchiseCode, franchiseName }]);
      setSelectedValueObject({ ...selectedValueObject, [franchiseCode]: { franchiseCode, franchiseName } });
      setInputValue("");
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setInputValue("")}>
      <Box sx={customStyle}>
        <div>
          <Typography variant="h3">{t("FRANCHISE")}</Typography>
          <InputWrapper>
            {selectedValues.map((option: IOptionData, index: number) => (
              <StyledTag key={option.franchiseCode}>
                <span>
                  {option.franchiseName}({option.franchiseCode})
                </span>{" "}
                <Close onClick={() => onDelete(option, index)} />
              </StyledTag>
            ))}
            <input
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={selectedValues.length ? "" : t("SEARCH_HERE")}
              value={inputValue}
            />
          </InputWrapper>
        </div>

        {inputValue && options.length ? (
          <Listbox>
            {options.map(({ franchiseName, franchiseCode }) => {
              return (
                <li
                  onClick={() => onSelect({ franchiseName, franchiseCode })}
                  aria-selected={selectedValueObject?.[franchiseCode as string] ? "true" : "false"}
                  key={franchiseCode}
                >
                  <span>
                    {franchiseName}({franchiseCode})
                  </span>{" "}
                  <Check fontSize="small" />
                </li>
              );
            })}
          </Listbox>
        ) : (
          <></>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default FranchiseMultiSelect;

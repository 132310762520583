import React, { useState } from "react";
import { Box, Button, Collapse, Divider, Popover, Stack, Typography } from "@mui/material";
import { Circle, ExpandLess, ExpandMore } from "@mui/icons-material";

const VersionSelector = ({
  versions = [],
  currentVersion,
  setCurrentVersion,
  status,
}: {
  currentVersion: number | null;
  versions: any[];
  setCurrentVersion: (val: number) => void;
  status?: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const iconColor = status?.toLowerCase() === "start" || status?.toLowerCase() === "success" ? "#0EB58E" : "#E1342C";
  const popoverId = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ marginLeft: "24px" }}>
      {versions.length > 0 && (
        <Button
          startIcon={
            <Circle
              sx={{
                color: iconColor,
                height: "8px",
                width: "8px",
                marginLeft: "3px",
              }}
            />
          }
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{
            border: "1px solid #D8D8D8",
            borderRadius: "18px",
            color: "#212121",
            backgroundColor: "#F4F4F4",
            verticalAlign: "-webkit-baseline-middle",
            height: "30px",
          }}
          aria-describedby={popoverId}
          component="button"
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              alignSelf: "baseline",
            }}
          >{`FEED ${currentVersion}`}</Typography>
          {open ? (
            <ExpandLess sx={{ margin: "8px 0px 8px 12px", color: "#63666A" }} />
          ) : (
            <ExpandMore sx={{ margin: "8px 0px 8px 12px", color: "#63666A" }} />
          )}
        </Button>
      )}

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          marginTop: "4px",
          ".MuiPaper-root": { borderRadius: "8px" },
        }}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Stack
            sx={{
              borderRadius: "4px",
              width: "100%",
              minWidth: "212px",
              flexDirection: "column",
            }}
          >
            {versions.map((_, i) => {
              return (
                <React.Fragment key={i}>
                  {i < 5 && (
                    <Button
                      sx={{
                        width: "100%",
                        padding: "10px 12px",
                        textTransform: "none",
                        justifyContent: "space-between",
                        borderRadius: 0,
                        "&:hover": {
                          backgroundColor: "#F4F4F4",
                        },
                      }}
                      onClick={() => {
                        setCurrentVersion(i + 1);
                        setAnchorEl(null);
                      }}
                    >
                      <Typography sx={{ color: "#212121", fontSize: "16px" }}>{`Feed ${i + 1}`}</Typography>
                      {/* <Typography sx={{ color: "#63666A", fontSize: "12px" }}>
                        {date}
                      </Typography> */}
                    </Button>
                  )}
                </React.Fragment>
              );
            })}
          </Stack>
          <Divider />
          <Typography sx={{ padding: "8px 36px", color: "#63666A", fontSize: "12px" }}>
            Only saves past 5 versions
          </Typography>
        </Collapse>
      </Popover>
    </Box>
  );
};

export default VersionSelector;

export const Errors = {
  messages: {
    data_format: "Data format issue",
    missing_data: "Mismatch/Missing data",
    blank_soldTo: "SoldTo Account ID is blank",
    no_order_found: "No Order Found To Change",
    timeout_error: "Timeout Error",
  },
  statuses: {
    timeout: "HTTP:TIMEOUT",
    service_unavailable: "HTTP:SERVICE_UNAVAILABLE",
    forbidden: "HTTP:FORBIDDEN",
    connectivity: "HTTP:CONNECTIVITY",
    bad_request: "HTTP:BAD_REQUEST",
    occ_bad_request: "OCC:BADREQUEST",
  },
};

import { DayOption, EOverallReportsConfig, CountryOption } from "./types";

export const dayOptions = [
  { value: "today", label: DayOption.TODAY },
  { value: "7", label: DayOption.SEVEN },
  { value: "30", label: DayOption.THIRTY },
];

export const countryOptions = [
  { value: "AU", label: CountryOption.AUSTRALIA },
  { value: "SG", label: CountryOption.SINGAPORE },
  { value: "NZ", label: CountryOption.NEWZEALAND },
  { value: "KR", label: CountryOption.SOUTHKOREA },
  { value: "TH", label: CountryOption.THAILAND },
  { value: "PH", label: CountryOption.PHILIPPINES },
  { value: "CN", label: CountryOption.CHINA },
  { value: "IN", label: CountryOption.INDIA },
  { value: "BD", label: CountryOption.BANGLADESH },
  { value: "HK", label: CountryOption.HONGKONG },
  { value: "TW", label: CountryOption.TAIWAN },
];

export const tabData = [{ label: "Sales" }, { label: "Transaction" }];

export const lineOptions: any = {
  plugins: {
    legend: { display: false },
  },
  responsive: true,
  scales: {
    x: {
      grid: { display: false },
    },

    y: {
      min: 0,
      // max: 100,
      ticks: {
        // stepSize: 100000,
        callback: (value: string) => {
          return "$" + parseInt(value) / 1000 + "k";
        },
      },
    },
  },
};

export const lineDataSet = {
  fill: true,
  borderColor: "#1E22AA",
  pointBackgroundColor: "#1E22AA",
  borderWidth: 1,
  lineTension: 0.4,
};

export const barOptions: any = {
  plugins: {
    legend: { position: "right", align: "end" },
  },
  responsive: true,
  scales: {
    x: {
      grid: { display: false },
      stacked: true,
    },
    y: { stacked: true },
  },
};

export const barDataSet = {
  pointStyle: "circle",
  pointBorderColor: "rgb(0, 0, 1)",
};

export const overallReportsConfig = {
  [EOverallReportsConfig.totalSales]: "total this ",
  [EOverallReportsConfig.orderCount]: "total this ",
  [EOverallReportsConfig.success]: "success",
  [EOverallReportsConfig.fail]: "fail",
};

import { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import { catalogTabs } from "../catalog/constants";
import Interface from "./interface";
import Overview from "./overview";
import { useUser } from "../../providers/UserProvider";
import { postApi } from "../../service";
import Loader from "../common/loader/Loader";
import Performance from "./performance";
import { IProductWatchForm } from "./types";
import { useEnvironment } from "../../providers/EnvironmentProvider";
import Satellite from "./satellite";

const Product = () => {
  const { id } = useParams();
  const { user } = useUser();
  const { environmentState } = useEnvironment();
  const formDetails = useForm<IProductWatchForm>({ defaultValues: { duration: "1 Month" } });
  const watchForm: IProductWatchForm = useWatch({ control: formDetails.control });

  const [tabValue, setTabValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [productInfo, setProductInfo] = useState<any>();

  const fetchProductInfo = async () => {
    setIsLoading(true);

    const res = await postApi(`${process.env.REACT_APP_POST}`, {
      api_name: "GetProductApiCatalogDetails",
      org: "hmd-jjcc-nonprod", // process.env.REACT_APP_ORG,
      sector: process.env.REACT_APP_SECTOR,
      countries: user.countries,
      product: id,
    });

    try {
      setProductInfo(res.data.data);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProductInfo();
  }, []);

  useEffect(() => {
    setTabValue(0);
  }, [environmentState]);

  const handleTabs = (value) => {
    switch (value) {
      case 1:
        return <Interface product={productInfo} setTabValue={setTabValue} formDetails={formDetails} />;

      case 2:
        return <Satellite product={productInfo} setTabValue={setTabValue} formDetails={formDetails} />;

      case 3:
        return id === "MARS" ? (
          <Performance product={productInfo} formDetails={formDetails} formData={watchForm} />
        ) : (
          <h1>In Progress...</h1>
        );

      default:
        return <Overview product={productInfo} />;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Tabs
            value={tabValue}
            onChange={(_e, newValue: number) => setTabValue(newValue)}
            aria-label="integration tabs"
            sx={{
              ".MuiTabs-flexContainer": {
                justifyContent: "center",
                gap: 8,
                ".MuiTab-root": { pb: 0, textTransform: "capitalize" },
              },
            }}
          >
            {catalogTabs.map(({ label }) => (
              <Tab key={label} label={label} />
            ))}
          </Tabs>
          {handleTabs(tabValue)}
        </>
      )}
    </>
  );
};

export default Product;

import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ChartOptions } from "chart.js";

import TopSellingProducts from "./TopSellingProducts";
import ProductTrend from "./ProductTrend";
import BasicHeader from "../../common/BasicHeader";
import InsightFilter from "../InsightFilter";
import BasicTabs from "../../common/BasicTabs";
import { options, optionsWithCurrency } from "../constants";
import { countryDetails } from "../../common/constants/countries";
import { useUser } from "../../../providers/UserProvider";

export interface IWatchForm {
  startDate?: Date;
  endDate?: Date;
  duration?: string;
  franchises?: { franchiseCode: string; franchiseName: string }[];
  soldTos?: string[];
  products?: string[];
}

export interface IProduct {
  productCode: string;
  productName: string;
  franchiseCode: string;
  franchiseName: string;
  countryCode: string;
}

const ProductInsights = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const formDetails = useForm({
    defaultValues: {
      duration: "1 Month",
      soldTos: user.soldToDetails ? user.soldToDetails.map(({ accountId }) => accountId) : [],
    },
  });
  const watchForm: IWatchForm = useWatch({ control: formDetails.control });
  const currency = countryDetails[user.countries[0]].currency;

  const [onSearch, setOnSearch] = useState(false);
  const [selectedValues, setSelectedValues] = useState<{ [key in string]: IProduct }>({});

  const toggleSearch = () => {
    setOnSearch(!onSearch);
  };

  const loadDefaultValues = (res) => {
    if (
      (res.data.data?.length === 0 || res.data.data?.length === undefined) &&
      watchForm.duration === "1 Month" &&
      !watchForm.franchises &&
      !watchForm.soldTos
    ) {
      formDetails.setValue("duration", "3 Month");
      toggleSearch();
      return true;
    }
    return false;
  };

  const handleSelectedProducts = (products) => setSelectedValues(products);

  return (
    <>
      <BasicHeader title={t("PRODUCT_INSIGHTS")} customStyle={{ pb: "20px" }} />
      <InsightFilter toggleSearch={toggleSearch} formDetails={formDetails} formData={watchForm} />
      <Stack direction="row" gap={6}>
        <TopSellingProducts onSearch={onSearch} formData={watchForm} loadDefaultValues={loadDefaultValues} />
        <Stack flex={1} sx={{ height: 535 }}>
          <Typography variant="h2" mt={2}>
            {t("PURCHASE_TREND")}
          </Typography>
          <BasicTabs
            data={[{ label: t("PRODUCT_VALUE") }, { label: t("PRODUCT_QUANTITY") }]}
            components={[
              <ProductTrend
                onSearch={onSearch}
                formData={watchForm}
                formDetails={formDetails}
                apiName="ProductInsightPurchaseTrendByValue"
                label={"Value"}
                selectedProducts={selectedValues}
                handleSelectedProducts={handleSelectedProducts}
              />,
              <ProductTrend
                onSearch={onSearch}
                formData={watchForm}
                formDetails={formDetails}
                apiName="ProductInsightPurchaseTrend"
                label="Quantity"
                selectedProducts={selectedValues}
                handleSelectedProducts={handleSelectedProducts}
              />,
            ]}
            currentTab={0}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ProductInsights;

import React, { useState } from "react";
import { Box, Collapse, Divider, List, ListItemButton, ListItemText, Popover, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { DropNTextCustomInputTextField, CustomListItemText } from "./styles";
import { ISearchFilterOptions } from "../types";

const ariaLabel = { "aria-label": "description" };

const DropNText: React.FC<ISearchFilterOptions> = ({
  customStyle = {},
  label,
  placeholder,
  optionData = [],
  handleOptionChange = () => {},
  handleTextChange = () => {},
  valueText,
  valueKey,
  showAll = true,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  // const [selectedOption, setSelectedOption] = useState<IOptionData>(preSelectedOption);

  return (
    <Box sx={customStyle}>
      <Typography variant="h3" sx={{ marginBottom: "8px" }}>
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4px",
          height: 34,
          border: "1px solid #C6C6C6",
        }}
      >
        <ListItemButton
          onClick={handleClick}
          sx={{ minWidth: "max-content", px: 1, padding: "0px 0px 0px 10px" }}
          component="button"
        >
          <CustomListItemText primary={valueKey?.label || "All"} sx={{ marginRight: "5px" }} />
          {open ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
          <Divider sx={{ height: 34, borderRightWidth: 1 }} />
        </ListItemButton>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ borderRadius: "4px", width: "100%", minWidth: 180 }}>
              {showAll && (
                <ListItemButton
                  onClick={() => {
                    handleOptionChange(optionData);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemText primary={"All"} />
                </ListItemButton>
              )}

              {optionData.map(({ value, label }) => (
                <ListItemButton
                  onClick={() => {
                    handleOptionChange([{ value, label }]);
                    setAnchorEl(null);
                  }}
                  key={value}
                >
                  <ListItemText primary={label} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </Popover>
        <DropNTextCustomInputTextField
          disableUnderline
          placeholder={placeholder}
          inputProps={ariaLabel}
          onChange={(e) => handleTextChange(e.target.value)}
          value={valueText ?? ""}
        />
      </Box>
    </Box>
  );
};

export default DropNText;

import { useState } from "react";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IFormDetails } from "../common/types";
import DateSelect from "../common/inputs/DateSelect";
import { btnPrimary, btnSec } from "../insightsv2/constants";
import { dateValues } from "../common/constants/inputs";
import CustomerMultiSelect from "../insightsv2/CustomerMultiSelect";

interface IInsightFilterProps {
  formDetails: IFormDetails;
  toggleSearch: () => void;
  formData: any;
}

const MarketInsightFilter: React.FC<IInsightFilterProps> = ({ toggleSearch, formDetails, formData }) => {
  const { t } = useTranslation();
  const { setValue, reset } = formDetails;

  const [isClear, setIsClear] = useState(false);

  const handleClearInputs = () => {
    setIsClear(!isClear);
    toggleSearch();
    reset();
  };

  return (
    <Stack direction="row" flexWrap="wrap" pb="20px" columnGap={2} rowGap={3} component="form">
      <CustomerMultiSelect setValue={setValue} isClear={isClear} />
      <DateSelect
        dateOptions={dateValues(t)}
        formDetails={formDetails}
        isClear={isClear}
        duration={formData?.duration || ""}
      />

      <Button variant="contained" sx={btnPrimary} onClick={toggleSearch} children={t("SEARCH")} />
      <Button sx={btnSec} onClick={handleClearInputs} children={t("RESET")} />
    </Stack>
  );
};

export default MarketInsightFilter;

/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, FC, useContext, useEffect, useState } from "react";
import { Alert, Box, Stack } from "@mui/material";
import { v4 as uuid } from "uuid";
import { IChildrenProps } from "../components/common/types";

export enum AlertTypeEnum {
  SUCCESS = "success",
  ERROR = "error",
}

export interface IAlertListProps {
  message: string;
  id: string;
  type: AlertTypeEnum;
}

export interface IAlertContext {
  alertList: IAlertListProps[];
  addAlert: (alertType: Omit<IAlertListProps, "id">) => void;
  removeAlert: (id: string) => void;
  setAlertList: (alertType: IAlertListProps[]) => void;
}

export const AlertContext = createContext<IAlertContext>({
  alertList: [],
  addAlert: () => {},
  removeAlert: () => {},
  setAlertList: () => {},
});

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within a AlertProvider");
  }
  return context;
};

export const AlertProvider: FC<IChildrenProps> = ({ children }) => {
  const [alertList, setAlertList] = useState<IAlertListProps[]>([]);
  const [removedAlerts, setRemovedAlerts] = useState<string[]>([]);

  useEffect(() => removedAlerts.forEach((e) => removeAlert(e)), [removedAlerts]);

  const addAlert = (val: Omit<IAlertListProps, "id">) => {
    const id = uuid();
    setAlertList([{ ...val, id }, ...alertList]);
    setTimeout(() => {
      setRemovedAlerts([...removedAlerts, id]);
    }, 5000);
  };

  const removeAlert = (uid: string) => {
    setAlertList([...alertList.filter(({ id }) => id !== uid)]);
  };

  return (
    <AlertContext.Provider
      value={{
        alertList,
        addAlert,
        removeAlert,
        setAlertList,
      }}
    >
      <Box position="relative">
        <Stack position="absolute" sx={{ left: "50%", transform: "translateX(-50%)", mt: 2 }}>
          {alertList.map(({ message, id, type }) => {
            return (
              <Alert severity={type} key={id} sx={{ mt: 1 }}>
                {message}
              </Alert>
            );
          })}
        </Stack>
      </Box>

      {children}
    </AlertContext.Provider>
  );
};

/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, ClickAwayListener, Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { UseFormSetValue } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";

import { InputWrapper, Listbox, StyledTag } from "../styles";
import { IProduct } from ".";

interface IProductMultiSelect {
  setValue: UseFormSetValue<any>;
  searchApi?: (val: any) => Promise<{ [key in string]: IProduct }>;
  selectedProducts: { [key in string]: IProduct };
  handleSelectedProducts: (val: { [key in string]: IProduct }) => void;
}

const ProductMultiSelect: React.FC<IProductMultiSelect> = ({
  setValue,
  searchApi,
  selectedProducts,
  handleSelectedProducts,
}) => {
  const { t } = useTranslation();
  const isClear = false;

  const [options, setOptions] = useState<{ [key in string]: IProduct }>({});
  const [inputValue, setInputValue] = useState<string>("");
  const [clearState, setClearState] = useState<boolean>(false);

  const debounce = useDebouncedCallback(
    async (val) => {
      if (searchApi) {
        setOptions(await searchApi(val));
      }
    },
    500,
    { maxWait: 2000 }
  );

  useEffect(() => {
    if (selectedProducts) {
      setValue(
        "products",
        Object.values(selectedProducts).map(({ productCode }) => productCode)
      );
    }
  }, [selectedProducts]);

  useEffect(() => {
    if (inputValue) {
      debounce(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (clearState !== isClear) {
      handleSelectedProducts({});
      setClearState(!clearState);
      setInputValue("");
    }
  }, [isClear]);

  const onDelete = (option: IProduct) => {
    const obj = { ...selectedProducts };
    delete obj[option.productCode];

    handleSelectedProducts(obj);
    setInputValue("");
  };

  const onSelect = (product: IProduct) => {
    const { productCode, productName, franchiseCode, franchiseName, countryCode } = product;

    if (selectedProducts?.[productCode]) {
      onDelete(product);
    } else {
      handleSelectedProducts({ ...selectedProducts, [product.productCode]: product });
      setInputValue("");
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setInputValue("")}>
      <Box>
        <div>
          <Typography variant="h3">{t("PRODUCT")}</Typography>
          <InputWrapper>
            {Object.values(selectedProducts).map((option: IProduct) => (
              <StyledTag key={option.productCode}>
                <span>{`${option.productName} (${option.productCode})`}</span>{" "}
                <Close onClick={() => onDelete(option)} />
              </StyledTag>
            ))}
            <input
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={Object.values(selectedProducts).length ? "" : t("SEARCH_HERE")}
              value={inputValue}
            />
          </InputWrapper>
        </div>
        {inputValue && options ? (
          <Listbox>
            {Object.values(options).map((option) => {
              const { productCode, productName } = option;

              return (
                <li
                  onClick={() => onSelect(option)}
                  aria-selected={selectedProducts?.productCode ? "true" : "false"}
                  key={productCode}
                >
                  <span>{`${productName} (${productCode})`}</span> <Check fontSize="small" />
                </li>
              );
            })}
          </Listbox>
        ) : (
          <></>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default ProductMultiSelect;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { OpenInFull } from "@mui/icons-material";
import { Chart as ChartJS, CategoryScale, PointElement, BarElement, Title, Tooltip, Legend } from "chart.js";

import BasicHeader from "../common/BasicHeader";
import BasicTabs from "../common/BasicTabs";
import Dropdown from "../common/inputs/Dropdown";
import DelimitBox from "../common/DelimitBox";
import MaximizeModal from "../common/modal/MaximizeModal";
import { modifyBarChartData } from "../common/chart/helper";
import {
  dayOptions,
  options,
  orderOverViewChartDataOptions,
  orderOverViewChartDataLineOptions,
  tabData,
} from "./constants";
import { DayOption, DayType, OptionDataN } from "../ordersOverview/types";
import { postApi } from "../../service";
import { ChartTypeEnum } from "../common/chart/constants";
import Chart from "../common/chart/Chart";
import { useUser } from "../../providers/UserProvider";

ChartJS.register(CategoryScale, PointElement, BarElement, Title, Tooltip, Legend);

interface ICustomersOverviewProps {
  type: ChartTypeEnum;
}

const CustomersOverview: React.FC<ICustomersOverviewProps> = ({ type }) => {
  const [openFull, setOpenFull] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dayOptionState, setDayOptionState] = useState<OptionDataN>({ value: DayType.SEVEN, label: DayOption.SEVEN });
  const [tabComponentData, setTabComponentData] = useState<any[]>([]);
  const { user } = useUser();
  const commonReq = {
    api_name: "Chart",
    countries: user.countries || [],
    chartType: "cc",
    filterType: dayOptionState.value,
    org: process.env.REACT_APP_ORG,
    sector: process.env.REACT_APP_SECTOR,
  };
  const leastRequestObject = {
    ...commonReq,
    cType: "least",
  };

  const mostRequestObject = {
    ...commonReq,
    cType: "most",
  };

  useEffect(() => {
    (async () => {
      let res = await postApi(`${process.env.REACT_APP_POST}`, mostRequestObject);
      let res2 = await postApi(`${process.env.REACT_APP_POST}`, leastRequestObject);

      if (type === ChartTypeEnum.LINE) {
        setTabComponentData([
          modifyBarChartData({ data: res.data.data.sales, ...orderOverViewChartDataLineOptions }),
          modifyBarChartData({ data: res2.data.data.sales, ...orderOverViewChartDataLineOptions }),
        ]);
      } else {
        setTabComponentData([
          modifyBarChartData({ data: res.data.data.sales, ...orderOverViewChartDataOptions }),
          modifyBarChartData({ data: res2.data.data.sales, ...orderOverViewChartDataOptions }),
        ]);
      }

      setIsLoading(false);
    })();
  }, [dayOptionState, type]);

  if (tabComponentData) {
    for (let i = 0; i < tabComponentData[0]?.datasets[0]?.data.length; i++) {
      if (tabComponentData[0]?.datasets[0]?.data.includes(0)) {
        let emptyValue = tabComponentData[0]?.datasets[0]?.data.indexOf(0);
        tabComponentData[0]?.datasets[0]?.data.splice(emptyValue, 1);
        tabComponentData[0]?.labels.splice(emptyValue, 1);
      }
    }
  }

  return (
    <DelimitBox customStyle={{ width: "calc(50% - 8px)", mr: 1 }}>
      <MaximizeModal open={openFull} setOpen={setOpenFull}>
        <BasicHeader title="Customers">
          <Dropdown
            optionData={dayOptions}
            preSelectedValue={dayOptionState}
            handleValueChange={(val) => setDayOptionState(val as OptionDataN)}
          />
          <OpenInFull
            sx={{ height: 34, ml: 2, cursor: "pointer" }}
            color="primary"
            onClick={() => setOpenFull(!openFull)}
          />
        </BasicHeader>

        {isLoading ? (
          "...Loading"
        ) : (
          <BasicTabs
            data={tabData}
            components={[
              ...tabComponentData.map((e, i) => {
                e?.datasets?.map((element: any) => {
                  if (!element?.label.includes(" ")) {
                    element.label =
                      element?.label?.charAt(0).toUpperCase() +
                      element?.label
                        ?.substr(1)
                        .replace(/([A-Z])/g, " $1")
                        .trim();
                  }
                });
                return (
                  <div style={{ height: openFull ? "65vh" : "45vh" }}>
                    <Chart chartType={type} data={e} options={options} />
                  </div>
                );
              }),
            ]}
          />
        )}
      </MaximizeModal>
    </DelimitBox>
  );
};

export default CustomersOverview;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import MainRoutes from "./routes/MainRoutes";
import { useAuth } from "./providers/AuthProvider";
import { useUser } from "./providers/UserProvider";
import { countryDetailsWithNames } from "./components/common/constants/countries";
import { postApi } from "./service";

const App = () => {
  const { setAuth } = useAuth();
  const { setUser } = useUser();
  const { search } = useLocation();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const localData = localStorage.getItem("auth") || "";
      let localAuth;
      try {
        localAuth = JSON.parse(localData);
      } catch {
        console.error("local auth not found");
      }
      let codeFromRequest = "";
      if (search.includes("code=")) {
        codeFromRequest = search.substring(1).replace("code=", "");
      }
      if (codeFromRequest.length > 1 || !localAuth?.access_token) {
        setLoading(false);
        return;
      }
      try {
        const fetchTokenBody = {
          api_name: `TokenInfo`,
          accessToken: localAuth?.access_token || "",
          email: localAuth?.email || "",
        };
        const response = await postApi(`${process.env.REACT_APP_POST}`, fetchTokenBody);
        if (response?.data?.err === "Token Expired") {
          setLoading(false);
          return;
        }
      } catch {
        setLoading(false);
        return;
      }

      //add comment
      const { code, email, name, role, access_token, expires_in, userName } = localAuth;

      setAuth({ code, access_token, expires_in });
      setUser({
        email,
        name,
        role,
        soldTo: localAuth.soldToAccountsDHP?.map((item) => item.accountId),
        soldToDetails: localAuth.soldToAccountsDHP,
        countries: [countryDetailsWithNames[localAuth.country].countryCode],
        userName,
      });
      setLoading(false);
    })();
  }, []);

  return <>{!loading && <MainRoutes />}</>;
};

export default App;

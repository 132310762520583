import { StatusTypeEnum } from "../components/common/table/types";

const headers = {
  "Content-Type": "application/json",
  org: process.env.REACT_APP_ORG || "",
};
const authHeader = {
  token: "",
  username: "",
};
try {
  const localData = localStorage.getItem("auth") || "";
  const localAuth = localData && JSON.parse(localData);

  authHeader.token = localAuth.access_token || "";
  authHeader.username = localAuth.email?.split("@")[0] || "";
} catch (err) {}
export const getApi = async (url: RequestInfo | URL) => {
  const res = await fetch(url);
  const data = await res.json();

  const statusCode = res.status;

  if (statusCode < 300) {
    return { data, statusCode, status: StatusTypeEnum.SUCCESS };
  } else {
    return { data, statusCode, status: StatusTypeEnum.ERROR };
  }
};

export const postApi = async (url: RequestInfo | URL, requestData: any) => {
  const body = JSON.stringify(requestData);

  const res = await fetch(url, { method: "POST", body, headers: { ...headers, ...authHeader } });
  const data = await res.json();

  const { status } = res;

  return { data, status };
};

export const postApiUserInfo = async (url: RequestInfo | URL, requestData: any) => {
  const body = JSON.stringify(requestData);
  const res = await fetch(url, { method: "POST", body, headers: headers });
  const data = await res.json();

  const { status } = res;

  return { data, status };
};

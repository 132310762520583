import { FC, useEffect, useState } from "react";
import { Avatar, Box, Divider, Grid, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { PriorityHigh } from "@mui/icons-material";
import { UseFormReturn } from "react-hook-form";

import PerformanceFilter from "./PerformanceFilter";
import SuccessRateAnalyticChart from "./SuccessRateAnalyticChart";
import ErrorCodeAnalyticsChart from "./ErrorCodeAnalyticsChart";
import ErrorCodeAnalyticsTable from "./ErrorCodeAnalyticsTable";
import CriticalInterface from "./CriticalInterface";
import TimeChart from "./TimeChart";
import { IProductWatchForm, ITimeAnalytics } from "../types";
import { reqTimeObj } from "./constants";
import { postApi } from "../../../service";
import { handleTimeChartData } from "./helper";
import Loader from "../../common/loader/Loader";
import TimeSummary from "./TimeSummary";
import SuccessInsight from "./SuccessInsight";
import { useEnvironment } from "../../../providers/EnvironmentProvider";

interface IPerformanceProps {
  product: any;
  formDetails: UseFormReturn<IProductWatchForm, any, undefined>;
  formData: IProductWatchForm;
}

const Performance: FC<IPerformanceProps> = ({ product, formDetails, formData }) => {
  const theme = useTheme();
  const { getValues } = formDetails;
  const { environmentState } = useEnvironment();

  const [resData, setResData] = useState<ITimeAnalytics[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [showCriticalInterface, setShowCriticalInterface] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const val = getValues();
      if (formData.interface) {
        let res: { data: { data: ITimeAnalytics[] }; status: number } = await postApi(`${process.env.REACT_APP_POST}`, {
          ...reqTimeObj,
          ...val,
          env: environmentState ? environmentState : process.env.PERFORMANCE_INDEX || null,
        });

        try {
          setResData(res.data.data);
          setShowCriticalInterface(false);
        } catch (err) {
          console.error(err);
        }
      } else {
        setResData(null);
        setShowCriticalInterface(true);
      }

      setIsLoading(false);
    })();
  }, [isSearched]);

  const toggleSearch = () => setIsSearched(!isSearched);

  return (
    <>
      <Typography variant="h1" sx={{ my: 2 }}>
        {product.name} Performance Insights
      </Typography>

      {/* Filter */}
      <PerformanceFilter toggleSearch={toggleSearch} formDetails={formDetails} formData={formData} product={product} />

      <Divider />

      {resData ? (
        isLoading ? (
          <Loader />
        ) : (
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item sm={12} md={6}>
              <Stack direction="row" my={3}>
                <Typography variant="h2">Transaction Time</Typography>
                <Tooltip title="Display of minimum, maximum, and average transaction durations.">
                  <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.primary.main, ml: 3 }}>
                    <PriorityHigh sx={{ width: 16, height: 16 }} />
                  </Avatar>
                </Tooltip>
              </Stack>

              <TimeChart transactionTimeChartData={handleTimeChartData(resData)} />
            </Grid>
            <Grid item sm={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h2" mt={3} mb={1}>
                Transaction Time Summary
              </Typography>

              <TimeSummary formDetails={formDetails} isSearched={isSearched} />
            </Grid>
          </Grid>
        )
      ) : (
        <></>
      )}

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item sm={12} md={6}>
          <Stack direction="row" my={3}>
            <Typography variant="h2">Success Rate Analysis</Typography>
            <Tooltip title="Percentage of successful and failed transactions over a given period.">
              <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.primary.main, ml: 3 }}>
                <PriorityHigh sx={{ width: 16, height: 16 }} />
              </Avatar>
            </Tooltip>
          </Stack>

          <SuccessRateAnalyticChart formDetails={formDetails} isSearched={isSearched} />
        </Grid>
        <Grid item sm={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h2" mt={3} mb={1}>
            Insights
          </Typography>
          <SuccessInsight formDetails={formDetails} isSearched={isSearched} />
        </Grid>
      </Grid>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item sm={12} md={6}>
          <Stack direction="row" my={3}>
            <Typography variant="h2">Error Code Analysis</Typography>

            <Tooltip title="Breakdown of error codes and their frequency.">
              <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.primary.main, ml: 3 }}>
                <PriorityHigh sx={{ width: 16, height: 16 }} />
              </Avatar>
            </Tooltip>
          </Stack>

          <Box sx={{ maxHeight: 600 }}>
            <ErrorCodeAnalyticsChart formDetails={formDetails} isSearched={isSearched} />
          </Box>
        </Grid>
        <Grid item sm={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h2" my={3}>
            Error Code Analysis
          </Typography>
          <ErrorCodeAnalyticsTable
            isSearched={isSearched}
            formDetails={formDetails}
            formData={formData}
            showCriticalInterface={showCriticalInterface}
          />
        </Grid>
      </Grid>

      {showCriticalInterface ? (
        <>
          <Stack direction="row" my={3}>
            <Typography variant="h2">Critical Interface</Typography>
            <Tooltip title="Identification of key interfaces based on their usage frequency.">
              <Avatar sx={{ width: 24, height: 24, bgcolor: theme.palette.primary.main, ml: 3 }}>
                <PriorityHigh sx={{ width: 16, height: 16 }} />
              </Avatar>
            </Tooltip>
          </Stack>
          <CriticalInterface formDetails={formDetails} isSearched={isSearched} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Performance;

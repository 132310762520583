export enum OrderEnum {
  ASC = "asc",
  DEC = "desc",
}
export enum StatusTypeEnum {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export interface ITableData {
  [key: string]: any;
}

export interface ITableRowFrame {
  name: string;
  type?: string;
  label: string;
  minWidth?: number;
  isVisible?: boolean;
  statusKey?: string;
  onClick?: (val: any) => void;
}

import { useEffect, useState } from "react";
import { Box, ClickAwayListener, SxProps, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { InputWrapper, Listbox } from "../../insightsv2/styles";
import { useUser } from "../../../providers/UserProvider";
import { useEnvironment } from "../../../providers/EnvironmentProvider";
import { postApi } from "../../../service";
import { IProductOptions, IProductWatchForm } from "../types";

interface IPerformanceMultiSelect {
  formDetails: UseFormReturn<IProductWatchForm, any, undefined>;
  customStyle?: SxProps;
  isClear?: boolean;
  product: IProductOptions;
}

const PerformanceMultiSelect: React.FC<IPerformanceMultiSelect> = ({
  formDetails,
  customStyle = {},
  isClear,
  product,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { environmentState } = useEnvironment();
  const { setValue, getValues, unregister } = formDetails;
  const formValues = getValues();

  const [selectedValue, setSelectedValue] = useState<string | null>(formValues.interface ? formValues.interface : null);
  const [options, setOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [clearState, setClearState] = useState(isClear);

  useEffect(() => {
    (async () => {
      const res: {
        data: { data: { interface: string }[] };
        status: number;
      } = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "GetInterfaceListForProduct",
        org: "hmd-jjcc-nonprod", // process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        countries: user.countries,
        product: product.value,
        env: environmentState ? environmentState : process.env.PERFORMANCE_INDEX || null,
      });

      setOptions(res.data.data.map((val) => val.interface).filter((val) => typeof val === "string" && val));
    })();
  }, []);

  useEffect(() => {
    if (clearState !== isClear) {
      setSelectedValue(null);
      setClearState(!clearState);
      setInputValue("");
    }
  }, [isClear]);

  const onDelete = () => {
    setSelectedValue(null);
    setInputValue("");
    unregister("interface");
  };

  const onSelect = (interfaceValue) => {
    setSelectedValue(interfaceValue);
    setValue("interface", interfaceValue);
    setInputValue("");
  };

  return (
    <ClickAwayListener onClickAway={() => setInputValue("")}>
      <Box sx={customStyle}>
        <div>
          <Typography variant="h3">Interface</Typography>
          <InputWrapper>
            {selectedValue ? (
              <>
                <span>{selectedValue}</span> <Close onClick={() => onDelete()} />
              </>
            ) : (
              <input
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={selectedValue ? "" : t("SEARCH_HERE")}
                value={inputValue}
              />
            )}
          </InputWrapper>
        </div>

        {inputValue && options.length ? (
          <Listbox>
            {options
              .filter((name) => name.toLowerCase().includes(inputValue.toLowerCase()))
              .map((interfaceName) => {
                return (
                  <li onClick={() => onSelect(interfaceName)} key={interfaceName}>
                    <span>{interfaceName}</span>
                  </li>
                );
              })}
          </Listbox>
        ) : (
          <></>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default PerformanceMultiSelect;

// Date Select

import { TFunction } from "i18next";

export const filterLayout = (t: TFunction) => [
  {
    type: "InputDate",
    label: t("SELECT_START_DATE"),
    placeholder: "YYYY-MM-DD",
    customStyle: { width: "204px" },
    formKey: "startDate",
  },
  {
    type: "InputDate",
    label: t("SELECT_END_DATE"),
    placeholder: "YYYY-MM-DD",
    customStyle: { width: "204px" },
    formKey: "endDate",
  },
];

export const dateValues = (t: TFunction) => [
  { label: t("ONE_MONTH"), value: "1 Month" },
  { label: t("THREE_MONTH"), value: "3 Month" },
  { label: t("SIX_MONTH"), value: "6 Month" },
];

import { Route, Routes } from "react-router-dom";
import { countryDetails } from "../components/common/constants/countries";
import Error from "../pages/Error";

import { useAuth } from "../providers/AuthProvider";
import { useUser } from "../providers/UserProvider";
import { privateRoutes, publicRoutes } from "./constants";
import PrivateRouteWrapper from "./PrivateRouteWrapper";
import PublicRouteWrapper from "./PublicRouteWrapper";

const MainRoutes = () => {
  const { auth } = useAuth();
  const { user } = useUser();

  return (
    <Routes>
      {auth.access_token
        ? //  PRIVATE ROUTE
          privateRoutes.map(({ path, element, expect }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRouteWrapper
                  children={expect?.some((e) => countryDetails[e].name === user.countries[0]) ? <Error /> : element}
                />
              }
            />
          ))
        : // PUBLIC ROUTE
          publicRoutes.map(({ path, element }) => (
            <Route key={path} path={path} element={<PublicRouteWrapper children={element} />} />
          ))}
    </Routes>
  );
};

export default MainRoutes;

import React from "react";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "90vh",
  maxWidth: "xl",
  maxHeight: "1900px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  ":focus-visible": { outline: "none" },
};

interface IMaximizeModalProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  children?: React.ReactNode;
}

const MaximizeModal: React.FC<IMaximizeModalProps> = ({ open, setOpen, children }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
      {children}
    </>
  );
};

export default MaximizeModal;

import { Container } from "@mui/material";

import SelfServiceConfiguration from "../components/configurationSettings/SelfServiceConfiguration";
import { ConfigProvider } from "../providers/ConfigProvider";

const SelfServiceConfigurationPage = () => {
  return (
    <Container maxWidth="xl">
      <ConfigProvider>
        <SelfServiceConfiguration />
      </ConfigProvider>
    </Container>
  );
};

export default SelfServiceConfigurationPage;

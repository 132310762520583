import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { postApi } from "../../../service";
import TrendInfoCard from "../../common/card/TrendInfoCard";
import { IPerformanceForSuccess, IProductWatchForm } from "../types";
import { dateValues } from "./constants";
import { useEnvironment } from "../../../providers/EnvironmentProvider";

interface ISuccessInsightProps {
  formDetails: UseFormReturn<IProductWatchForm, any, undefined>;
  isSearched: boolean;
}

const SuccessInsight: FC<ISuccessInsightProps> = ({ formDetails, isSearched }) => {
  const { getValues } = formDetails;
  const { t } = useTranslation();
  const { environmentState } = useEnvironment();

  const [performanceForSuccess, setPerformanceForSuccess] = useState<IPerformanceForSuccess | null>(null);
  const [currentTimeline, setCurrentTimeline] = useState<string>("");

  useEffect(() => {
    (async () => {
      const val = getValues();

      let data: { data: { data: IPerformanceForSuccess }; status: number } = await postApi(
        `${process.env.REACT_APP_POST}`,
        {
          org: "hmd-jjcc-nonprod-qa",
          sector: "medical",
          api_name: "PerformanceInsightForSuccessRateInsight",
          ...val,
          env: environmentState ? environmentState : process.env.PERFORMANCE_INDEX || null,
        }
      );

      try {
        setPerformanceForSuccess(data.data.data);
        setCurrentTimeline(val.duration ?? "");
      } catch (err) {}
    })();
  }, [isSearched]);

  return (
    <>
      {performanceForSuccess && (
        <Box sx={{ alignContent: "center", flex: 1 }}>
          <TrendInfoCard
            isTrendingUp={performanceForSuccess.failureRate.includes("decreased") ? false : true}
            content={`Compared to previous filtered duration (
                ${dateValues(t).find(({ value }) => value === currentTimeline)?.label})`}
            value={`${performanceForSuccess.failureRate} %`}
          />
          <TrendInfoCard
            isTrendingUp={performanceForSuccess.successRate.includes("decreased") ? false : true}
            content={`Compared to previous filtered duration (
                ${dateValues(t).find(({ value }) => value === currentTimeline)?.label})`}
            value={`${performanceForSuccess.successRate} %`}
          />
        </Box>
      )}
    </>
  );
};

export default SuccessInsight;

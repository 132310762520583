import { ChartTypeEnum } from "../common/chart/constants";

export enum dataKeyType {
  TOTAL_SALE = "totalSales",
  SUCCESS = "success",
  FAIL = "fail",
  NEW_SALE = "newSale",
  OLD_SALE = "oldSale",
}

export enum DayType {
  DAY = "day",
  TODAY = "today",
  MONTH = "month",
  WEEK = "week",
  SEVEN = "7",
  THIRTY = "30",
}

export enum DayOption {
  DAILY = "Daily",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly",
  TODAY = "Today",
  SEVEN = "Last 7 days",
  THIRTY = "Last 30 days",
}

export enum CountryType {
  AU = "AU",
  SG = "SG",
  NZ = "NZ",
  KR = "KR",
  TH = "TH",
  PH = "PH",
  CN = "CN",
  IN = "IN",
  BD = "BD",
  HK = "HK",
  TW = "TW",
}

export enum CountryOption {
  CHINA = "China",
  INDIA = "India",
  AUSTRALIA = "Australia",
  SINGAPORE = "Singapore",
  NEWZEALAND = "New Zealand",
  SOUTHKOREA = "South Korea",
  THAILAND = "Thailand",
  PHILIPPINES = "Philippines",
  BANGLADESH = "Bangladesh",
  HONGKONG = "Hong Kong",
  TAIWAN = "Taiwan",
}

export enum EOverallReportsConfig {
  totalSales = "totalSales",
  orderCount = "orderCount",
  success = "success",
  fail = "fail",
}

export interface OptionDataN {
  value?: DayType;
  label?: DayOption;
}

export interface CountryOptionDataN {
  value?: CountryType;
  label?: CountryOption;
}

export interface IOrderAmountProps {
  title: string;
  amount: string;
  growth?: {
    type: string;
    value: string;
  };
}

export interface ISalesActivityProps {}

export interface IComponentData {
  orderAmounts: IOrderAmountProps[];
  chart: {
    chartType: ChartTypeEnum;
    data: any;
    options: any;
  };
}

export interface ICustomerDetailsData {
  sales: any;
  type: any;
  activeCustomerData: any;
  countAllSoldToAccountData: any;
  countActiveSoldToAccountData: any;
  customerDetailsData: any;
  leastRevenueSoldToAccountData: any;
  topPerformingSoldToAccountData: any;
  noOfOrdersLeastRevenueData: any;
  inactiveCustomerData: any;
  totalActiveCustomerData: any;
  topThreePerformingCustomerData: any;
  totalSalesData: any;
  topSalesData: any;
  totalOrdersCreatedData: any;
  totalReturnOrdersData: any;
  totalTransactionsData: any;
  totalFailedTransactionsData: any;
  totalSalesPerCustomerPerMonth: any;
  totalProductsSoldData: any;
  topSoldProductData: any;
  topReturnedProductData: any;
}

export interface IResData {
  month: number;
  total: number;
}

export interface Datasets {
  data: number[];
  fill: boolean;
  label: string;
  borderColor: string;
  backgroundColor: string;
  pointBackgroundColor: string;
  borderWidth: number;
  lineTension: number;
}

import { useEffect, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";

import Chart from "../common/chart/Chart";
import { ChartTypeEnum } from "../common/chart/constants";
import { getApi } from "../../service";
import { formatTotalSalesPerCustomerPerMonthData } from "./helper";

const ReportChart = () => {
  const [totalSalesPerCustomerPerMonth, setTotalSalesPerCustomerPerMonth] = useState<any>({ data: [] });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const res = await getApi(`${process.env.REACT_APP_CHART_URL}?customerReport=true&chartType=cc`);
      setTotalSalesPerCustomerPerMonth(
        formatTotalSalesPerCustomerPerMonthData(res.data.totalSalesPerCustomerPerMonth.data)
      );
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rounded" width={"100%"} height={360} sx={{ mb: 2 }} />
      ) : (
        <Box sx={{ width: "100%", p: "15px" }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>Active customer sales activity</Typography>
          <Chart
            chartType={ChartTypeEnum.LINE}
            data={totalSalesPerCustomerPerMonth}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "bottom",
                },
              },
            }}
          />
        </Box>
      )}
    </>
  );
};

export default ReportChart;

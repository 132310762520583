import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ViewStreamOutlined } from "@mui/icons-material";

import DelimitBox from "../../common/DelimitBox";

interface IProductCardProps {
  name: string;
  description: string;
  interfaceCount: number;
}

const ProductCard: React.FC<IProductCardProps> = ({ name, description, interfaceCount }) => {
  const theme = useTheme();

  return (
    <DelimitBox
      customStyle={{ maxWidth: 400, width: "100%", height: "100%", overflow: "hidden", position: "relative" }}
    >
      <Typography
        variant="h1"
        sx={{
          position: "relative",
          mt: 3,
          "&:before": {
            content: `""`,
            height: 40,
            width: 6,
            top: -6,
            left: -20,
            position: "absolute",
            bgcolor: theme.palette.primary.main,
          },
        }}
      >
        {name}
      </Typography>
      <Box
        sx={{
          position: "relative",
          my: 3,
          width: 1,
          height: "1px",
          p: "1px",
          background: `linear-gradient(90deg, ${theme.palette.primary.main} 16%, rgba(255,255,255,0) 100%)`,
        }}
      />
      <Typography marginBottom={"56px"}>{description}</Typography>
      <Stack direction="row" sx={{ position: "absolute", bottom: 0, my: 3 }}>
        <ViewStreamOutlined sx={{ my: "auto" }} />
        <Typography sx={{ lineHeight: "32px" }}>
          <span style={{ margin: "0 10px" }}>{interfaceCount}</span>
          Total Interface
        </Typography>
      </Stack>
    </DelimitBox>
  );
};

export default ProductCard;

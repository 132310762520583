import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";

import NavigateBack from "../components/navbar/NavigateBack";
import { tableLayoutSap } from "../components/transactions/constants";
import Transactions from "../components/transactions/Transactions";
import { countryDetails } from "../components/common/constants/countries";
import { useUser } from "../providers/UserProvider";

const SAPTransactions = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const tableLayout = tableLayoutSap(t);
  if (user.countries && user.countries.length > 1)
    tableLayout.filterLayout.push({
      type: "MultiSelectDropdown",
      label: "Select Country",
      placeholder: "Select Country",
      optionData: user.countries.map((item) => {
        return {
          value: item,
          label: countryDetails[item].name,
        };
      }),
      customStyle: { width: "260px" },
      formKey: "countries",
    });
  return (
    <Container maxWidth="xl">
      <NavigateBack headerText="SELF SERVICE" />

      <Transactions {...tableLayout} />
    </Container>
  );
};

export default SAPTransactions;

import { Fragment, SyntheticEvent, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DelimitBox from "../common/DelimitBox";
import { insightTabs } from "./constants";
import ProductInsights from "./productInsights";
import OrderInsights from "./orderInsights";
import CustomerInsights from "./customerInsights";
import MarketInsights from "./marketInsights";
import { useUser } from "../../providers/UserProvider";
import { countryDetails } from "../common/constants/countries";
import InvoiceInsights from "./invoiceInsights";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, value, index, ...rest }: TabPanelProps) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...rest}
    sx={{ p: 3 }}
  >
    {value === index && children}
  </Box>
);

const InsightsV2 = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { user } = useUser();

  const [tabIndex, setTabIndex] = useState(state?.tab ?? 0);

  return (
    <DelimitBox customStyle={{ width: 1, display: "flex" }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabIndex}
        onChange={(_e: SyntheticEvent, val: number) => setTabIndex(val)}
        sx={{ borderRight: 1, borderColor: "divider", minWidth: "15vw" }}
      >
        {insightTabs(t).map((e, i) =>
          user.countries[0] !== countryDetails.SG.countryCode && i === 4 ? (
            <Fragment key={e} />
          ) : (
            <Tab
              label={e}
              key={e}
              id={`vertical-tab-${i}`}
              sx={{ alignItems: "start" }}
              aria-controls={`vertical-tabpanel-${i}`}
            />
          )
        )}
      </Tabs>
      <TabPanel value={tabIndex} index={0} children={<MarketInsights />} />
      <TabPanel value={tabIndex} index={1} children={<OrderInsights />} />
      <TabPanel value={tabIndex} index={2} children={<ProductInsights />} />
      <TabPanel value={tabIndex} index={3} children={<CustomerInsights />} />
      <TabPanel value={tabIndex} index={4} children={<InvoiceInsights />} />
    </DelimitBox>
  );
};

export default InsightsV2;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton, Skeleton, Stack } from "@mui/material";
import { Tune } from "@mui/icons-material";

import BasicHeader from "../common/BasicHeader";
import DelimitBox from "../common/DelimitBox";
import Table from "../common/table/Table";
import { ITableData, ITableRowFrame, StatusTypeEnum } from "../common/table/types";
import { AlertTypeEnum, useAlert } from "../../providers/AlertProvider";
import { getApi, postApi } from "../../service";
import { IServiceList } from "../transactions/types";
import { Errors } from "../common/constants/errorDetails";
import { useForm } from "../../providers/FormProvider";
import { IPastActionData } from "../selfServiceDetails/types";
import OrderDetailsForm from "../selfServiceDetails/OrderDetailsForm";
import TemporaryDrawer from "../selfServiceDetails/pastActionDrawer/TemporaryDrawer";
import AmountDetail from "../selfServiceDetails/transactionServiceDetails/AmountDetail";
import EditableButton from "../selfServiceDetails/transactionServiceDetails/EditableButton";
import DetailsErrorMessage from "../selfServiceDetails/transactionServiceDetails/DetailsErrorMessage";
import { convertSubmitData } from "../selfServiceDetails/helper";
import { useUser } from "../../providers/UserProvider";

interface ITransactionServiceDetailsProps {
  title: string;
  status: StatusTypeEnum;
  auditRequestData: { [key in string]: string };
  productRequestData: { [key in string]: string };
  fixedHeadCells: ITableRowFrame[];
  flexibleHeadCells: ITableRowFrame[];
  formList: IServiceList[][];
  versions?: any[];
  setVersions?: (val: any[]) => void;
  currentVersion: number | null;
  setCurrentVersion: (val: number) => void;
  error?: string;
  fullMessage?: string | undefined;
}

const TransactionTab: React.FC<ITransactionServiceDetailsProps> = ({
  title,
  status,
  auditRequestData,
  productRequestData,
  fixedHeadCells,
  flexibleHeadCells,
  formList,
  versions,
  setVersions,
  currentVersion,
  setCurrentVersion,
  error,
  fullMessage,
}) => {
  const { addAlert } = useAlert();
  const { setIsEditable } = useForm();

  const [transactionDetailsData, setTransactionDetailsData] = useState<IServiceList[][]>([]);
  const [configData, setConfigData] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openCustomizeColumns, setOpenCustomizeColumns] = useState<boolean>(false);

  const [pastActionData, setPastActionData] = useState<IPastActionData[] | null>(null);

  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [fixedTableColumn, setFixedTableColumn] = useState<ITableRowFrame[]>([]);
  const [flexibleTableColumn, setFlexibleTableColumn] = useState<ITableRowFrame[]>([]);
  const { user } = useUser();
  useEffect(() => {
    setFixedTableColumn(fixedHeadCells);
    setFlexibleTableColumn(flexibleHeadCells);

    effectChange();
  }, []);

  useEffect(() => {
    if (typeof currentVersion === "number" && versions) {
      setTransactionDetailsData(
        formList.map((arr) =>
          arr.map(({ name, ...rest }) => ({
            ...rest,
            name,
            disabled: configData[name] ?? false,
            value: versions?.[currentVersion - 1]?._source?.[name],
          }))
        )
      );
    }
  }, [currentVersion]);

  const effectChange = () => {
    (async () => {})();
  };

  useEffect(() => {
    (async () => {
      const res = await postApi(`${process.env.REACT_APP_POST}`, auditRequestData);

      setVersions && setVersions(res.data.data);
      setCurrentVersion(1);

      const resConfig = await getApi(`${process.env.REACT_APP_URL}/configurations?format=editable&type=${title}`);
      setConfigData(resConfig.data);

      if (res.data.data.length) {
        let version = res.data.data[0];

        setTransactionDetailsData(
          formList.map((arr) =>
            arr.map(({ name, valueKey, ...rest }) => ({
              ...rest,
              name,
              disabled: resConfig.data[name] ?? false,
              value:
                valueKey && version._source?.[name]?.[valueKey]
                  ? version._source[name][valueKey]
                  : version._source[name],
            }))
          )
        );

        // Table Data
        const resTable = await postApi(`${process.env.REACT_APP_POST}`, productRequestData);
        setTableData(resTable.data.data.result.map((e) => ({ ...e, ...e._source })));
        setFixedTableColumn(fixedHeadCells);
        setFlexibleTableColumn(flexibleHeadCells);
      }

      setIsLoading(false);
    })();
  }, []);

  const handleCustomizeColumnsDrawerOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpenCustomizeColumns(true);
  };

  const handleFormSubmit = async () => {
    // await postApi(`${process.env.REACT_APP_POST_URL}/transactions/submit?type=${title}`, transactionDetailsData);

    await postApi(`${process.env.REACT_APP_POST}`, {
      type: "SAP",
      category: title,
      api_name: "Reprocess",
      countries: user.countries || [],
      data: convertSubmitData(transactionDetailsData),
      org: process.env.REACT_APP_ORG,
      sector: process.env.REACT_APP_SECTOR,
    });

    setIsEditable(false);
    addAlert({ message: "Order Successfully Submitted", type: AlertTypeEnum.SUCCESS });
  };

  const replaceWithUserFriendlyMessage = (error) => {
    if (!error) {
      return "";
    }
    let statusMessage = error;
    if (
      statusMessage.toLowerCase().includes("does not exist") ||
      statusMessage.toLowerCase().includes("that is not defined") ||
      statusMessage.toLowerCase().includes("multiple items found")
    ) {
      return Errors.messages.missing_data;
    } else if (
      statusMessage.toLowerCase().includes("sold_to") ||
      statusMessage.toLowerCase().includes("account not found")
    ) {
      return Errors.messages.blank_soldTo;
    } else if (statusMessage.toLowerCase().includes("no order found")) {
      return Errors.messages.no_order_found;
    } else if (error.status_code === Errors.statuses.timeout) {
      return Errors.messages.timeout_error;
    }
    return Errors.messages.data_format;
  };

  return (
    <Stack sx={{ position: "relative" }}>
      {status?.toUpperCase() === StatusTypeEnum.ERROR && (
        <DetailsErrorMessage message={replaceWithUserFriendlyMessage(error)} fullMessage={fullMessage} />
      )}

      {isLoading ? (
        <Skeleton variant="rounded" width={"80vw"} height={"60vh"}>
          <CircularProgress />
        </Skeleton>
      ) : (
        <>
          {status?.toUpperCase() === StatusTypeEnum.ERROR && <EditableButton handleFormSubmit={handleFormSubmit} />}

          <DelimitBox customStyle={{ flex: 1 }}>
            <BasicHeader
              title={title.charAt(0).toUpperCase() + title.slice(1) + " Details"}
              leftElements={pastActionData && <TemporaryDrawer title={title} pastActionData={pastActionData} />}
            />

            <form>
              <OrderDetailsForm
                transactionServiceData={transactionDetailsData}
                setTransactionServiceData={setTransactionDetailsData}
              />
            </form>
          </DelimitBox>

          <DelimitBox customStyle={{ p: 0, mt: 2 }}>
            <BasicHeader title="Products" customStyle={{ p: "20px" }}>
              <IconButton
                color="primary"
                aria-label="add an alarm"
                onClick={handleCustomizeColumnsDrawerOpen}
                sx={{ ...(openCustomizeColumns && { display: "none" }) }}
              >
                <Tune />
              </IconButton>
            </BasicHeader>

            <Table
              transactionData={tableData}
              isViewMoreBtnExist={true}
              fixedTableColumn={fixedTableColumn}
              setFixedTableColumn={setFixedTableColumn}
              flexibleTableColumn={flexibleTableColumn}
              setFlexibleTableColumn={setFlexibleTableColumn}
              openCustomizeColumns={openCustomizeColumns}
              setOpenCustomizeColumns={setOpenCustomizeColumns}
            />
            <AmountDetail />
          </DelimitBox>
        </>
      )}
    </Stack>
  );
};

export default TransactionTab;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Modal, Stack, Typography, useTheme } from "@mui/material";

import { useForm } from "../../../providers/FormProvider";

interface IBacktrackModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
};

const BacktrackModal: React.FC<IBacktrackModalProps> = ({ open, setOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setIsEditable } = useForm();

  return (
    <Modal
      open={open}
      //   onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight={800}>
            Are you sure?
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <Close sx={{ color: `${theme.palette.primary.main}` }} />
          </IconButton>
        </Stack>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          You are about to leave this page without submitting. All changes will be lost.
        </Typography>

        <Stack direction="row" justifyContent="space-between" marginTop={4}>
          <Button
            variant="buttonOutlined"
            onClick={() => {
              navigate(-1);
              setIsEditable(false);
            }}
          >
            LEAVE PAGE
          </Button>
          <Button
            variant="buttonContained"
            sx={{ "&:hover": { backgroundColor: "#025569" } }}
            onClick={() => setOpen(false)}
          >
            CONTINUE EDITING
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default BacktrackModal;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { Stack, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { CustomInputDate } from "./styles";
import { ViewTypeEnum } from "./types";
import { ISearchFilterOptions } from "../types";

const InputDate: React.FC<ISearchFilterOptions> = ({
  label,
  placeholder,
  date,
  customStyle,
  viewType = ViewTypeEnum.EDITABLE,
  disabled,
  handleChange = () => {},
}) => {
  const { pathname } = useLocation();

  const [value, setValue] = useState<Dayjs | null>(null);
  const minDate = dayjs().subtract(6, "month");
  const maxDate = dayjs();

  useEffect(() => setValue(null), [pathname]);
  const [open, setOpen] = useState(false);

  const handleTogglePicker = () => {
    setOpen(!open);
  };
  return (
    <Stack sx={customStyle}>
      {viewType === ViewTypeEnum.READABLE ? (
        <>
          <Typography variant="subtitle1">{label}</Typography>
          <Typography variant="subtitle2" sx={{ mt: 1 }}>
            {dayjs(date).isValid() ? dayjs(date).format("YYYY-MM-DD") : "-"}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h3">{label}</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              open={open}
              disabled={disabled}
              label="Basic example"
              value={value}
              views={["year", "month", "day"]}
              maxDate={maxDate}
              minDate={minDate}
              onChange={(newValue: dayjs.Dayjs | null) => {
                !newValue && handleChange("");
                dayjs(newValue).isValid() && handleChange(dayjs(newValue).format("YYYY-MM-DD"));
                setValue(newValue);
                setOpen(false);
              }}
              OpenPickerButtonProps={{
                onClick: handleTogglePicker,
                style: { color: disabled ? "#888B8D" : "#0C8CAA" },
              }}
              disableHighlightToday={true}
              showDaysOutsideCurrentMonth={true}
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => (
                <CustomInputDate
                  {...params}
                  disabled={disabled}
                  InputLabelProps={{ shrink: true }}
                  onClick={handleTogglePicker}
                  inputProps={{ ...params.inputProps, placeholder, readOnly: true }}
                  sx={{
                    background: disabled ? "#F4F4F4" : "#fff",
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#63666A",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </>
      )}
    </Stack>
  );
};

export default InputDate;

import React from "react";
import { Box, Button, Divider, Popover, Stack, Typography } from "@mui/material";
import { KeyboardArrowDown, PersonOutlineOutlined } from "@mui/icons-material";

import { useUser } from "../../providers/UserProvider";
import { IUserSettingsProps } from "./types";

const UserSettings: React.FC<IUserSettingsProps> = () => {
  const { user } = useUser();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeToken = () => {
    localStorage.removeItem("auth");
    window.location.reload();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          borderRadius: "27px",
          ml: "20px",
          padding: "10px",
          "&:hover": {
            backgroundColor: "#F4F4F4",
          },
        }}
      >
        <Stack sx={{ alignItems: "center" }} direction="row">
          <Stack
            direction="row"
            sx={{
              borderRadius: "50%",
              display: "flex",
              backgroundColor: "#B41601",
              height: 36,
              width: 36,
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <PersonOutlineOutlined sx={{ color: "#fff", fontSize: "29.25px" }} />
          </Stack>
          <Box sx={{ mx: 1, textAlign: "left" }}>
            <Typography variant="h3">{user.name}</Typography>
            {/* <Typography variant="subtitle1" fontWeight={500} color={"text.secondary"}>
              0001234567
            </Typography> */}
          </Box>
          <KeyboardArrowDown color="primary" />
        </Stack>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          marginTop: "13px",
          ".MuiPaper-root": {
            borderRadius: "8px",
          },
        }}
      >
        <Box sx={{ width: "100%", minWidth: "220px" }}>
          {/* <Button variant="customButton">Account settings</Button> */}
          <Divider sx={{ margin: "0px 12px" }} />
          <Button variant="customButton" onClick={removeToken}>
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default UserSettings;

import { Dispatch, FC, SetStateAction, useState } from "react";
import { Box, Button, IconButton, Stack, Tooltip as MuiTooltip, Typography, useTheme } from "@mui/material";
import { AccountTree, ResetTv, ShareOutlined } from "@mui/icons-material";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, SubTitle } from "chart.js";
import { Bar } from "react-chartjs-2";

import DelimitBox from "../../common/DelimitBox";
import { IFormDetails } from "../../common/types";
import webMethodLogo from "../../../media/images/webmethods_logo.png";
import mulesoftLogo from "../../../media/images/mulesoft_logo.png";
import InfoModal from "./InfoModal";
import { cardChartOption } from "../performance/constants";
import { handleSuccessChartData } from "../performance/helper";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, SubTitle, Tooltip, Legend);

interface IInterfaceCardProps {
  name: string;
  description: string;
  lastUpdated: number;
  integration_flow: string;
  reusability: string;
  middleware: string;
  handleClick: () => void;
  setTabValue: Dispatch<SetStateAction<number>>;
  formDetails: IFormDetails;
  successCount: number;
  failureCount: number;
  successRate: number;
  failureRate: number;
}

const InterfaceCard: FC<IInterfaceCardProps> = ({
  name,
  description,
  lastUpdated,
  integration_flow,
  reusability,
  middleware,
  handleClick,
  setTabValue,
  formDetails,
  successCount,
  failureCount,
  successRate,
  failureRate,
}) => {
  const theme = useTheme();
  const { setValue } = formDetails;

  const [isFlowModalOpen, setIsFlowModalOpen] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);

  return (
    <>
      <DelimitBox
        customStyle={{
          width: 400,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        {reusability.toLowerCase() === "yes" && (
          <Box sx={{ ml: -2.5, mt: -2.5, pb: 2 }}>
            <Box
              sx={{
                minWidth: "130px",
                height: "40px",
                backgroundColor: `${theme.palette.primary.main}`,
                position: "relative",
                padding: "0 12px",
                display: "inline-block",
                "&:before": {
                  content: `""`,
                  width: 0,
                  height: 0,
                  borderRight: "9.6px solid transparent",
                  borderTop: `13px solid ${theme.palette.primary.main}`,
                  display: "block",
                  position: "absolute",
                  bottom: "-13px",
                  left: 0,
                },
              }}
            >
              <Typography variant="h2" color="white" lineHeight={"40px"}>
                Reusable
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "-20px",
                  "&:before": {
                    content: `""`,
                    width: 0,
                    height: 0,
                    display: "block",
                    borderRight: "20px solid transparent",
                    borderTop: `20px solid ${theme.palette.primary.main}`,
                  },
                  "&:after": {
                    content: `""`,
                    width: 0,
                    height: 0,
                    display: "block",
                    borderRight: "20px solid transparent",
                    borderBottom: `20px solid ${theme.palette.primary.main}`,
                  },
                }}
              ></Box>
            </Box>
          </Box>
        )}
        <Typography variant="h1">{name}</Typography>
        <Box
          sx={{
            position: "relative",
            my: 3,
            width: 1,
            height: "1px",
            p: "1px",
            background: `linear-gradient(90deg, ${theme.palette.primary.main} 16%, rgba(255,255,255,0) 100%)`,
          }}
        />
        <Box sx={{ minHeight: 80 }}>
          {description && description.length > 120 ? (
            !isShow ? (
              <>
                <Typography sx={{ display: "inline" }}>{description.substring(0, 120)}...</Typography>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsShow(true);
                  }}
                >
                  More
                </Button>
              </>
            ) : (
              <>
                <Typography sx={{ display: "inline" }}>{description}</Typography>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsShow(false);
                  }}
                >
                  Less
                </Button>
              </>
            )
          ) : (
            <Typography>{description}</Typography>
          )}
        </Box>

        <Box flex={1}>
          <Bar
            options={cardChartOption}
            data={handleSuccessChartData([{ range: "One Month", successCount, failureCount }])}
          />
          {successCount || failureCount ? (
            <Stack direction={"row"} justifyContent={"center"} textAlign={"center"} gap={2} >
              <Typography sx={{ width: 128, textWrap: "nowrap",  }}>Success - {successRate}%</Typography>
              <Typography sx={{ width: 128, textWrap: "nowrap" }}>Error - {failureRate}%</Typography>
            </Stack>
          ) : (
            <></>
          )}
        </Box>

        <Stack direction="row" sx={{ my: 3 }}>
          <ShareOutlined sx={{ my: "auto", mr: 2 }} />
          <Typography>last updated {new Date(lastUpdated).toLocaleDateString("en-GB")}</Typography>
        </Stack>

        <Stack direction="row">
          <MuiTooltip title="Performance">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setTabValue(3);
                setValue("interface", name);
              }}
            >
              <ResetTv />
            </IconButton>
          </MuiTooltip>

          <MuiTooltip title="Integration flow">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsFlowModalOpen(true);
              }}
            >
              <AccountTree />
            </IconButton>
          </MuiTooltip>

          <MuiTooltip title="Middleware">
            <Box sx={{ height: 40, padding: 1 }}>
              {middleware.toLowerCase() === "webmethod" ? (
                <img height={24} srcSet={webMethodLogo} src={webMethodLogo} alt={"webMethod"} loading="lazy" />
              ) : middleware.toLowerCase() === "mulesoft" ? (
                <img height={24} srcSet={mulesoftLogo} src={mulesoftLogo} alt={"mulesoft"} loading="lazy" />
              ) : (
                <></>
              )}
            </Box>
          </MuiTooltip>
        </Stack>
      </DelimitBox>

      <InfoModal
        isModalOpen={isFlowModalOpen}
        setIsModalOpen={setIsFlowModalOpen}
        text={integration_flow}
        header="Integration Flow"
      />
    </>
  );
};

export default InterfaceCard;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, ClickAwayListener, List, ListItemButton, Typography } from "@mui/material";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";

import { CustomDateSelectButton } from "../../common/inputs/styles";

interface ISelectProductProps {
  setInterfaceProduct: (val: string) => void;
  productOptions: { label: string; value: string }[];
}

const SelectProduct: React.FC<ISelectProductProps> = ({ setInterfaceProduct,  productOptions }) => {
  const [openList, setOpenList] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState("All");


  const handleClose = ({ label, value }) => {
    setInterfaceProduct(value);
    setSelectedProductType(label);
    setOpenList(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpenList(false)}>
        <Box sx={{ position: "relative"}}>
          <Typography variant="h3">Satelite</Typography>
          <CustomDateSelectButton
            id="select-customized-button"
            aria-controls={openList ? "select-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openList ? "true" : undefined}
            variant="outlined"
            disableElevation
            onClick={() => setOpenList(true)}
            sx={{ color: selectedProductType ? "#0C8CAA" : "#63666A" }}
          >
            {selectedProductType || "Select Product"}{" "}
            <KeyboardArrowDownOutlined sx={{ position: "relative", right: "-24px" }} />
          </CustomDateSelectButton>
          {openList && (
            <List
              sx={{
                width: 160,
                maxWidth: 360,
                bgcolor: "background.paper",
                position: "absolute",
                border: "1px #D8D8D8 solid",
                borderRadius: "8px",
                zIndex: 1,
                padding: "10px",
              }}
              aria-labelledby="nested-list-subheader"
            >
              {productOptions.map((e) => (
                <ListItemButton onClick={() => handleClose(e)} disableRipple key={e.value}>
                  {e.label}
                </ListItemButton>
              ))}
            </List>
          )}
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default SelectProduct;

import { useLocation } from "react-router-dom";
import { Breadcrumbs, Container, Link, Typography } from "@mui/material";

import { routeDefinitions } from "./constants";

const Breadcrumb = () => {
  const { pathname } = useLocation();
  const filteredRoute = routeDefinitions.filter((e) => pathname.includes(e.path));

  return (
    <>
      {filteredRoute.length > 1 && (
        <Container maxWidth="xl" sx={{ pt: 4, pb: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            {filteredRoute.map(({ label, path }, i: number) => {
              if (filteredRoute.length - 1 === i) {
                return (
                  <Typography variant="overline" color="#212121" sx={{ fontWeight: "bold" }} key={path}>
                    {label}
                  </Typography>
                );
              }
              return (
                <Link href={path} variant="overline" key={path} underline="none">
                  {label}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Container>
      )}
    </>
  );
};

export default Breadcrumb;

import React from "react";

export enum ViewTypeEnum {
  READABLE = "readable",
  EDITABLE = "editable",
  DISABLED = "disabled",
}

/*
  -----DROPDOWN-----
*/
export interface IOptionData {
  value?: string;
  label?: string;
  icon?: React.ReactElement;
}

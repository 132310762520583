import { Cancel, CheckCircle } from "@mui/icons-material";

import { StatusTypeEnum } from "../common/table/types";
import { StyledTabs } from "../common/types";
import { IServiceList } from "../transactions/types";
import { IIconWithLabelProps } from "./types";

export const convertSubmitData = (orderData: IServiceList[][]) => {
  let temp = {};

  orderData.forEach((arr) => {
    arr.forEach(({ name, value, valueKey }) => {
      let ref = temp;

      if (valueKey) {
        const list = valueKey.split(".");
        const len = list.length;

        for (let i = 0; i < len - 1; i++) {
          const elem = list[i];
          if (!ref[elem]) ref[elem] = {};
          ref = ref[elem];
        }
        ref[list[len - 1]] = value;
      } else {
        temp[name] = value;
      }
    });
  });

  return temp;
};

export const getIcon = (status: StatusTypeEnum): React.ReactElement | null => {
  const successIcon = <CheckCircle color="success" sx={{ fontSize: "13.33px" }} />;
  const errorIcon = <Cancel color="error" sx={{ fontSize: "13.33px" }} />;

  if (status?.toUpperCase() === StatusTypeEnum.SUCCESS) return successIcon;
  else if (status?.toUpperCase() === StatusTypeEnum.ERROR) return errorIcon;
  return null;
};

export const getDataWithIcons = ({ status, label, disabled = true }: IIconWithLabelProps): StyledTabs => {
  const icon = status && getIcon(status);
  if (icon) return { label, icon };

  return { label, disabled };
};

export const workOnData = (val: any, str: string, versionToProcess: number = 0) => {
  switch (str) {
    case "order":
    case "delivery":
    case "invoice":
      return val?.data?.result[versionToProcess]?._source;

    case "inboundOrders":
      return {
        ...val.payload.content.orderCreateRequest[0].header,
        entries: [...val.payload.content.orderCreateRequest[0].lineItem],
      };

    default:
      return {};
  }
};

export const handleNodeClick = (props: any): number => {
  const { type, number, navigate, search } = props;

  let updatedSearch = search
    .substring(1)
    .split("&")
    .map((e: string) => {
      let b = e.split("=");

      if (b[0] === type) {
        b[1] = number;
      }

      return b.join("=");
    })
    .join("&");

  switch (type) {
    case "hybris":
      navigate(`/self-service/jjcc/details/inboundOrders?inboundOrders=${number}`);
      return 0;

    case "order":
      navigate(`/self-service/sap/details/order?${updatedSearch}`);
      return 0;

    case "delivery":
      navigate(`/self-service/sap/details/delivery?${updatedSearch}`);
      return 1;

    case "invoice":
      navigate(`/self-service/sap/details/invoice?${updatedSearch}`);
      return 2;

    default:
      return -1;
  }
};

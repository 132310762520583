import { ChartData } from "chart.js";
import dayjs from "dayjs";
import { chartColors } from "../../common/chart/constants";
import { initDetails } from "./constants";
import { IErrorRateAnalytics } from "../types";

export const handleTimeChartData = (val): ChartData<"line"> => {
  let labels = val.map(({ date }, index) => {
    let a = val[index - 1];

    if (!index) {
      return dayjs(date).format("MMMM");
    }
    if (index && dayjs(date).format("MMMM") !== dayjs(a.date).format("MMMM")) {
      return dayjs(date).format("MMMM");
    }

    return "";
  });

  return {
    labels,
    datasets: [
      {
        data: val.map(({ minResponseTime }, index) => ({ ...val, x: labels[index], y: minResponseTime })),
        pointRadius: 5,
        tension: 0.3,
        borderColor: chartColors[0],
        backgroundColor: chartColors[0] + "99",
        label: "Minimum",
        fill: true,
        hidden: true,
      },
      {
        data: val.map(({ averageResponseTime }, index) => ({ ...val, x: labels[index], y: averageResponseTime })),
        pointRadius: 5,
        tension: 0.3,
        borderColor: chartColors[15],
        backgroundColor: chartColors[15] + "BB",
        label: "Average",
        fill: true,
      },
      {
        data: val.map(({ maxResponseTime }, index) => ({ ...val, x: labels[index], y: maxResponseTime })),
        pointRadius: 5,
        tension: 0.3,
        borderColor: chartColors[17],
        backgroundColor: chartColors[17] + "99",
        label: "Maximum",
        fill: true,
        hidden: true,
      },
    ],
  };
};

export const handleSuccessChartData = (val): ChartData<"bar"> => ({
  labels: val.map(({ range }) => range),
  datasets: [
    {
      data: val.map(({ successCount }) => successCount),
      backgroundColor: chartColors[0],
      hoverBackgroundColor: chartColors[0],
    },
    {
      data: val.map(({ failureCount }) => failureCount),
      backgroundColor: chartColors[1],
      hoverBackgroundColor: chartColors[1],
    },
  ],
});

export const handleErrorChartData = (val: IErrorRateAnalytics): ChartData<"doughnut"> => ({
  labels: Object.values(val).map(({ message, value }) => `${message} (${value})`),
  datasets: [
    {
      data: Object.values(val).map(({ value }) => value),
      backgroundColor: chartColors,
      hoverOffset: 4,
    },
  ],
});

import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { DragIndicator, Visibility, VisibilityOff } from "@mui/icons-material";
import { Draggable } from "react-beautiful-dnd";

import { ITableRowFrame } from "../types";

interface IDropCustomizeColumns {
  droppableId: string;
  fixedTableColumn: ITableRowFrame[];
  flexibleTableColumn: ITableRowFrame[];
  setFlexibleTableColumn: React.Dispatch<React.SetStateAction<ITableRowFrame[]>>;
}

const CustomizeColumns: React.FC<IDropCustomizeColumns> = ({
  droppableId,
  fixedTableColumn,
  flexibleTableColumn,
  setFlexibleTableColumn,
}) => {
  const theme = useTheme();
  const fixed = droppableId === "fixed-list";

  const handleColumnVisibility = (index: number, isVisible: boolean) => {
    !fixed &&
      setFlexibleTableColumn([
        ...flexibleTableColumn.slice(0, index),
        {
          ...flexibleTableColumn[index],
          isVisible,
        },
        ...flexibleTableColumn.slice(index + 1),
      ]);
  };

  const tableColumn = fixed ? fixedTableColumn : flexibleTableColumn;

  return (
    <>
      {tableColumn.map(({ name, label, isVisible = true }, index) => {
        return (
          <Draggable key={name} draggableId={name} index={index}>
            {(provided) => (
              <>
                <Stack
                  direction="row"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  sx={{
                    alignItems: "center",
                    maxHeight: "40px",
                    padding: "8px 0px",
                  }}
                >
                  <Box {...provided.dragHandleProps} sx={{ marginRight: "16px" }}>
                    <DragIndicator />
                  </Box>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: "normal",
                      cursor: "pointer",
                      flex: 1,
                    }}
                  >
                    {label}
                  </Typography>
                  <Box sx={{ cursor: "pointer" }} onClick={() => handleColumnVisibility(index, !isVisible)}>
                    {isVisible ? (
                      <Visibility
                        sx={{
                          color: fixed ? `${theme.palette.grey.A100}` : `${theme.palette.primary.main}`,
                        }}
                      />
                    ) : (
                      <VisibilityOff sx={{ color: `${theme.palette.primary.main}` }} />
                    )}
                  </Box>
                </Stack>
                {tableColumn.length - 1 > index && <Divider />}
              </>
            )}
          </Draggable>
        );
      })}
    </>
  );
};

export default CustomizeColumns;

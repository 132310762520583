import { IChartProps } from "../common/chart/types";

export enum dataKeyType {
  TOTAL_SALE = "totalSales",
  SUCCESS = "success",
  FAIL = "fail",
  NEW_SALE = "newSale",
  OLD_SALE = "oldSale",
}

export enum DayType {
  DAY = "day",
  TODAY = "today",
  MONTH = "month",
  WEEK = "week",
  SEVEN = "7",
  THIRTY = "30",
  THREE_MONTHS = "threeMonths",
  SIX_MONTHS = "sixMonths",
}

export enum DayOption {
  DAILY = "Daily",
  WEEKLY = "7 Days",
  MONTHLY = "6 Months",
  TODAY = "Today",
  SEVEN = "7 Days",
  THIRTY = "1 Month",
  THREE_MONTHS = "3 Months",
}

export enum EOverallReportsConfig {
  totalSales = "totalSales",
  invoiceCount = "invoiceCount",
  deliveryCount = "deliveryCount",
  orderCount = "orderCount",
  orderSuccess = "orderSuccess",
  orderFail = "orderFail",
  invoiceSuccess = "invoiceSuccess",
  invoiceFail = "invoiceFail",
  deliverySuccess = "deliverySuccess",
  deliveryFail = "deliveryFail",
}

export interface OptionDataN {
  value: DayType;
  label: DayOption;
}

export interface IOrderAmountProps {
  title: string;
  amount: string;
  growth?: {
    type: string;
    value: string;
  };
}

export interface IOrdersOverviewProps {}

export interface IComponentData {
  orderAmounts: IOrderAmountProps[];
  chart: IChartProps;
}

export interface IResData {
  month: number;
  total: number;
}

export interface Datasets {
  data: number[];
  fill: boolean;
  label: string;
  borderColor: string;
  backgroundColor: string;
  pointBackgroundColor: string;
  borderWidth: number;
  lineTension: number;
}

export interface DocumentResponse {
  fields: {
    [key in string]: EOverallReportsConfig[];
  };
  sales: { [key in string]: any }[];
  type: DayType;
}

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MenuItem, ListItemText, Checkbox, Divider, Typography, Box, IconButton, OutlinedInput } from "@mui/material";
import { Done, Remove } from "@mui/icons-material";

import { CustomMultiSelectDropdown } from "./styles";
import { IOptionData } from "./types";
import { ISearchFilterOptions } from "../types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = { PaperProps: { style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 } } };

const Icon = (
  <IconButton
    style={{
      height: "24px",
      width: "24px",
      border: "1px solid #888B8D",
      borderRadius: "4px",
    }}
  />
);
const CheckedIcon = (
  <IconButton
    style={{
      color: "white",
      backgroundColor: "#0C8CAA",
      height: "24px",
      width: "24px",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Done sx={{ padding: "1px" }} />
  </IconButton>
);
const IndeterminateIcon = (
  <IconButton
    style={{
      color: "white",
      backgroundColor: "#0C8CAA",
      height: "24px",
      width: "24px",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Remove />
  </IconButton>
);

const MultiSelectDropdown: React.FC<ISearchFilterOptions> = ({
  customStyle,
  label,
  placeholder,
  optionData = [],
  handleChange = () => {},
  showAll = true,
}) => {
  const { pathname } = useLocation();

  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => setSelected([]), [pathname]);

  const isAllSelected = optionData.length > 0 && selected.length === optionData.length;

  const handleDropChange = (e: any) => {
    const value = e.target.value;
    if (value[value.length - 1] === "all") {
      const structuredData = optionData.map(({ value }) => value ?? "");
      setSelected(selected.length === structuredData.length ? [] : structuredData);
      handleChange(selected.length === structuredData.length ? [] : structuredData);
      return;
    }
    setSelected(value);
    handleChange(value);
  };

  const handleClickAll = () => {
    console.log("ALL CLICK", optionData, selected);
  };

  return (
    <Box sx={customStyle}>
      <Typography variant="h3">{label}</Typography>

      <CustomMultiSelectDropdown
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        input={<OutlinedInput label="Name" />}
        displayEmpty
        value={selected}
        onChange={handleDropChange}
        inputProps={{ "aria-label": "Without label" }}
        renderValue={(selected) => {
          return selected && Array.isArray(selected) && selected.length ? (
            selected.map((e) => optionData.find((ele) => ele.value === e)?.label).join(", ")
          ) : (
            <span style={{ color: "gray" }}>{placeholder}</span>
          );
        }}
        MenuProps={MenuProps}
      >
        {showAll && (
          <>
            <MenuItem value="all" sx={{ bgcolor: isAllSelected ? "#0c8caa14" : "white" }} onClick={handleClickAll}>
              <Checkbox
                checked={isAllSelected}
                indeterminate={selected.length > 0 && selected.length < optionData.length}
                icon={Icon}
                checkedIcon={CheckedIcon}
                indeterminateIcon={IndeterminateIcon}
              />
              <ListItemText primary="Select all" sx={{ "& span": { fontSize: "14px" } }} />
            </MenuItem>
            <Divider sx={{ marginTop: "0 !important", marginBottom: "5px !important" }} />
          </>
        )}
        {optionData.map(({ value, label }: IOptionData) => (
          <MenuItem value={value} key={value}>
            <Checkbox checked={selected.indexOf(value ?? "") > -1} icon={Icon} checkedIcon={CheckedIcon} />
            <ListItemText primary={label} sx={{ "& span": { fontSize: "14px" } }} />
          </MenuItem>
        ))}
      </CustomMultiSelectDropdown>
    </Box>
  );
};

export default MultiSelectDropdown;

import React from "react";
import {
  Box,
  colors,
  Divider,
  IconButton,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import BasicHeader from "../../common/BasicHeader";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1000px",
  height: "800px",
  maxWidth: "xl",
  maxHeight: "1900px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  ":focus-visible": { outline: "none" },
};

interface IInterfaceFieldsModalProps {
  open: boolean;
  interfaceDetails: any;
  handleClose: () => void;
}

const { blue } = colors;
const InterfaceFieldsModal: React.FC<IInterfaceFieldsModalProps> = ({ open, interfaceDetails, handleClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BasicHeader title={interfaceDetails.interface} customStyle={{ paddingBottom: "24px" }}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </BasicHeader>

          <Divider />
          <div style={{ height: "640px", overflowY: "auto" }}>
            <Stack direction="row" sx={{ mt: 2 }}>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead sx={{ bgcolor: blue[50] }}>
                    <TableRow>
                      <TableCell sx={{ borderRight: 1, fontWeight: 700, py: 1 }}>Name</TableCell>
                      <TableCell sx={{ fontWeight: 700, py: 1 }}>{t("VALUE")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(fieldMapping).map((key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row" sx={{ borderRight: 1, py: 1 }}>
                          {fieldMapping[key]}
                        </TableCell>
                        <TableCell sx={{ py: 1 }}>{interfaceDetails[key]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <TableContainer component={Paper} sx={{ ml: 4 }}>
              <Table aria-label="customized table">
                <TableBody>
                  {modalRows2.map(({ name, value }) => (
                    <TableRow key={name}>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{
                          borderRight: 1,
                          borderColor: "transparent",
                          py: 1,
                          fontSize: name === "STATUS" ? 10 : 13,
                          color: name === "STATUS" ? "gray" : "black",
                        }}
                      >
                        {name}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          borderColor: "transparent",
                          py: 1,
                          fontSize: name === "STATUS" ? 10 : 13,
                          color: name === "STATUS" ? "gray" : "black",
                        }}
                      >
                        {value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
            </Stack>
          </div>
          <Divider />
        </Box>
      </Modal>
    </>
  );
};

export default InterfaceFieldsModal;

const fieldMapping = {
  sector: "Sector",
  interface_product: "Interface product",
  interface_type: "Interface type",
  interface: "Interface",
  data_classifier: "Data classifier",
  src_name: "Source name",
  target_name: "Target name",
  src_connection_type: "Source connection type",
  target_connection_type: "Target connection type",
  // interface_description: "Interface description",
  market: "Market",
  data_classification: "Data classification",
  gxp: "Good X practices",
  sox: "Sarbanes oxley act",
  project: "Project",
  key_dataDesc: "Key Data field",
  key_dataValue: "Key Data value",
  business_contact: "Business contact",
  ricef_id: "Ricef id",
  process_area: "Process area",
  reusability: "Reusability",
  middleware: "Middleware",
  integration_flow: "Integration flow",
  support_contact: "Support contact",
  product_owner: "Product owner",
  satellite: "Satellite",
  integration_pattern:"Integration pattern"

};

import { FC, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, SubTitle } from "chart.js";
import { Bar } from "react-chartjs-2";
import { UseFormReturn } from "react-hook-form";

import { postApi } from "../../../service";
import Loader from "../../common/loader/Loader";
import { analyticsDetailsLabel, initDetails, reqSuccessObj, successChartOption } from "./constants";
import { handleSuccessChartData } from "./helper";
import { IProductWatchForm } from "../types";
import { useEnvironment } from "../../../providers/EnvironmentProvider";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, SubTitle, Tooltip, Legend);

interface IAnalyticsDetails {
  range: string;
  successCount: number;
  failureCount: number;
  totalCount: number;
  successRate: number;
  failureRate: number;
}

interface ISuccessRateAnalytics extends IAnalyticsDetails {
  endDate: string;
}

interface ISuccessRateAnalyticChartProps {
  formDetails: UseFormReturn<IProductWatchForm, any, undefined>;
  isSearched: boolean;
}

const SuccessRateAnalyticChart: FC<ISuccessRateAnalyticChartProps> = ({ formDetails, isSearched }) => {
  const { getValues } = formDetails;
  const { environmentState } = useEnvironment();

  const [chartData, setChartData] = useState<ISuccessRateAnalytics[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [analyticsDetails, setAnalyticsDetails] = useState<IAnalyticsDetails>(initDetails);

  useEffect(() => {
    (async () => {
      let res: { data: { data: { result: ISuccessRateAnalytics[]; total: ISuccessRateAnalytics } }; status: number } =
        await postApi(`${process.env.REACT_APP_POST}`, {
          ...reqSuccessObj,
          ...getValues(),
          env: environmentState ? environmentState : process.env.PERFORMANCE_INDEX || null,
        });
      try {
        setChartData(res.data.data.result);
        setAnalyticsDetails(res.data.data.total);
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    })();
  }, [isSearched]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Stack direction="row" gap={6}>
        <Box flex={1}>
          {chartData ? <Bar options={successChartOption} data={handleSuccessChartData(chartData)} /> : <></>}
        </Box>

        <Box sx={{ alignContent: "center" }}>
          {Object.entries(analyticsDetailsLabel).map(([key, value]) => {
            return (
              <Box key={key}>
                <Typography component="span" variant="subtitle1">
                  {value} :
                </Typography>
                <Typography component="span" variant="subtitle1">
                  {analyticsDetails[key]} {value.includes("Rate") ? "%" : ""}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Stack>
    </>
  );
};

export default SuccessRateAnalyticChart;

import i18n from 'i18next';
import {
  initReactI18next
} from 'react-i18next';
import Backend from 'i18next-http-backend';
import {countryDetailsWithNames} from './../src/components/common/constants/countries';

// import LanguageDetector from 'i18next-browser-languagedetector';
// keeping for future purpose , might need later.


i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector) // keeping for future purpose , might need later.
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

const localData = localStorage.getItem("auth") || "";
let localAuth;
try {
  localAuth = JSON.parse(localData);
  const theLocaleCode = countryDetailsWithNames[localAuth.country].localeCode;
  if (theLocaleCode && theLocaleCode.length > 0) {
    i18n.changeLanguage(theLocaleCode);
  }
} catch {
  console.error("local auth not found");
}


export default i18n;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { useTranslation } from "react-i18next";

import { options } from "./../constants";
import Chart from "../../common/chart/Chart";
import { ChartTypeEnum } from "../../common/chart/constants";
import { AlertTypeEnum, useAlert } from "../../../providers/AlertProvider";
import { postApi } from "../../../service";
import Loader from "../../common/loader/Loader";
import { purchaseOrderDataToChartData } from "../helper";
import { useUser } from "../../../providers/UserProvider";
import { countryDetails } from "../../common/constants/countries";
import { IWatchForm } from "../productInsights";

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend, Filler);

const InvoiceChart = ({ onSearch, formData }: { onSearch: boolean; formData: IWatchForm }) => {
  const { t } = useTranslation();
  const { control } = useForm();
  const watchForm = useWatch({ control });
  const { addAlert } = useAlert();
  const { user } = useUser();

  const dateFormat = countryDetails[user.countries[0]].dateFormat;

  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dataFetch = async () => {
    try {
      setIsLoading(true);
      let res = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "InvoiceInsightForTotalInvoicedValuePattern",
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        countries: user.countries,
        ...formData,
      });

      const { data, status, message } = res.data;

      if (!status) {
        setChartData([]);
        addAlert({ message, type: AlertTypeEnum.ERROR });
      } else {
        setChartData(data);
      }
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    dataFetch();
  }, [watchForm, onSearch]);

  return (
    <Box sx={{ width: 1, position: "relative" }}>
      {!isLoading ? (
        chartData.length ? (
          <>
            <Typography
              variant="body1"
              align="center"
              sx={{
                transform: "rotate(-90deg)",
                transformOrigin: "0 0",
                position: "absolute",
                top: 200,
                left: 0,
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {t("VALUE")}
            </Typography>
            <Box sx={{ height: 330, ml: 4 }} flex={1}>
              <Chart
                chartType={ChartTypeEnum.LINE}
                data={purchaseOrderDataToChartData(chartData, dateFormat)}
                options={options}
              />
              <Typography variant="body1" align="center" sx={{ fontSize: "14px", fontWeight: 600 }}>
                Pattern
              </Typography>
            </Box>
          </>
        ) : (
          <></>
        )
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default InvoiceChart;

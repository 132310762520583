import { Circle } from "@mui/icons-material";
import { TFunction } from "i18next";

import { IOptionData } from "../common/inputs/types";
import { ITableRowFrame, StatusTypeEnum } from "../common/table/types";

export const defaultValues = { status: "ERROR" };

export const sapFlexibleList: ITableRowFrame[] = [
  { name: "sapOrderNumber", type: "status", label: "Order No.", minWidth: 142, statusKey: "orderStatus" },
  { name: "deliveryNumber", type: "multiple", label: "Delivery No.", minWidth: 142, statusKey: "deliveryStatus" },
  { name: "invoiceNumber", type: "multiple", label: "Invoice No.", minWidth: 142, statusKey: "invoiceStatus" },
  { name: "purchaseOrderNumber", label: "P.O. No." },
  { name: "orderType", label: "Order Type" },
  { name: "date", type: "date", label: "Order Created Date" },
  { name: "Unit_uid", label: "Account ID" },
  { name: "accountName", label: "Account Name" },
  { name: "country", type: "country", label: "Country" },
];

export const jjccFlexibleList: ITableRowFrame[] = [
  { name: "gatewayOrderNumber", type: "status", label: "Hybris Order No.", statusKey: "orderStatus" },
  { name: "errorDesc", label: "Error Description" },
  { name: "orderType", label: "Order Type" },
  { name: "orderCreateDate", type: "date", label: "Order Create Date" },
  { name: "soldToNumber", label: "Account ID" },
  { name: "accountName", label: "Account Name" },
  { name: "country", type: "country", label: "Country" },
];

export const optionSAPData: IOptionData[] = [
  { value: "all", label: "All" },
  { value: "order", label: "Order No." },
  { value: "delivery", label: "Delivery No." },
  { value: "invoice", label: "Invoice No." },
  { value: "poNum", label: "P.O. No." },
  { value: "accountId", label: "Account ID" },
];

export const optionNewSAPData: IOptionData[] = [
  { value: "all", label: "All" },
  { value: "sapOrderNumber", label: "Order No." },
  { value: "sapDeliveryNumber", label: "Delivery No." },
  { value: "sapInvoiceNumber", label: "Invoice No." },
  { value: "poNum", label: "P.O. No." },
  { value: "accountId", label: "Account ID" },
];

export const optionJJCCData: IOptionData[] = [
  { value: "all", label: "All" },
  { value: "gatewayOrderNumber", label: "Hybris Order No." },
  { value: "accountId", label: "Account ID" },
];

export const rowsPerPageOptions = [
  { label: "5 Items", value: 5 },
  { label: "10 Items", value: 10 },
  { label: "20 Items", value: 20 },
  { label: "50 Items", value: 50 },
];

export const droppableMap = [
  { droppableId: "fixed-list", titleHeader: "Fixed Column" },
  { droppableId: "flexible-list", titleHeader: "Drag Column" },
];

export const transactionStatus: IOptionData[] = [
  { value: "", label: "Select All" },
  {
    value: StatusTypeEnum.ERROR,
    label: "Error",
    icon: <Circle sx={{ color: "#E1342C", fontSize: "10px !important" }} />,
  },
  {
    value: StatusTypeEnum.SUCCESS,
    label: "Success",
    icon: <Circle sx={{ color: "#0EB58E", fontSize: "10px !important" }} />,
  },
];

export const dropNTextFilter = [
  {
    type: "DropNText",
    label: "Search by",
    placeholder: "Search by Order No., Delivery No., Invoice No., P.O. No., Account ID",
    optionData: optionNewSAPData,
    customStyle: { width: "419px" },
  },
];
export const dropNTextFilter2 = [
  {
    type: "DropNText",
    label: "Search by",
    placeholder: "Search by Hybris Order No., Delivery No., Invoice No., P.O. No., Account ID",
    optionData: optionJJCCData,
    customStyle: { width: "419px" },
  },
];

export const filterTransaction = (t: TFunction) => [
  {
    type: "InputDate",
    label: t("SELECT_START_DATE"),
    placeholder: "YYYY-MM-DD",
    customStyle: { width: "204px" },
    formKey: "fromOrderDate",
  },
  {
    type: "InputDate",
    label: t("SELECT_END_DATE"),
    placeholder: "YYYY-MM-DD",
    customStyle: { width: "204px" },
    formKey: "toOrderDate",
  },
];

export const filterTransactionJjcc = (t: TFunction) => [
  {
    type: "InputDate",
    label: t("SELECT_START_DATE"),
    placeholder: "YYYY-MM-DD",
    customStyle: { width: "204px" },
    formKey: "fromOrderDate",
  },
  {
    type: "InputDate",
    label: t("SELECT_END_DATE"),
    placeholder: "YYYY-MM-DD",
    customStyle: { width: "204px" },
    formKey: "toOrderDate",
  },
];

export const filterSAP = [
  {
    type: "Dropdown",
    label: "Transaction Status",
    placeholder: "Select Status",
    customStyle: { minWidth: "204px" },
    formKey: "status",
    optionData: transactionStatus,
  },
];

export const tableLayoutDashboard = (t: TFunction) => ({
  header: "",
  tableLayout: sapFlexibleList,
  filterLayout: [...dropNTextFilter, ...filterTransaction(t)],
});

export const tableLayoutJjcc = (t: TFunction) => ({
  header: "JJCC Transactions",
  name: "inboundTransactions",
  arr: jjccFlexibleList,
  filter: [...dropNTextFilter2, ...filterTransactionJjcc(t), ...filterSAP],
});

export const tableLayoutSap = (t: TFunction) => ({
  header: "SAP Transactions",
  tableLayout: sapFlexibleList,
  filterLayout: [...dropNTextFilter, ...filterTransaction(t), ...filterSAP],
});

import Authentication from "../pages/Authentication";
// import Dashboard from "../pages/Dashboard";
import Error from "../pages/Error";
import CustomerReport from "../pages/Customers";
import SAPTransactions from "../pages/SAPTransactions";
import SelfServiceConfigurationPage from "../pages/SelfServiceConfigurationPage";
import SelfServiceDetailsPage from "../pages/SelfServiceDetailsPage";
// import InsightsPage from "../pages/InsightsPage";
import InsightsPageV2 from "../pages/InsightsPageV2";
import TransactionDetailsPage from "../pages/TransactionDetailsPage";
import JJCCTransactions from "../pages/JJCCTransactions";
import Home from "../pages/home";
import InterfaceCatalog from "../pages/InterfaceCatalog";
import ProductPage from "../pages/ProductPage";

interface IPrivateRoutes {
  path: string;
  element: any;
  expect?: string[];
}

export const publicRoutes = [
  { path: "*", element: <Error /> },
  { path: "/", element: <Authentication /> },
];

export const privateRoutes: IPrivateRoutes[] = [
  { path: "*", element: <Error /> },
  // { path: "/", element: <Dashboard /> },
  { path: "/", element: <InsightsPageV2 /> },
  { path: "/home", element: <Home /> },
  // { path: "/insights", element: <InsightsPageV2 /> },
  { path: "/report", element: <CustomerReport /> },
  { path: "/catalog", element: <InterfaceCatalog /> },
  { path: "/catalog/overview/:id", element: <ProductPage /> },
  { path: "/self-service/sap", element: <SAPTransactions /> },
  { path: "/self-service/jjcc", element: <JJCCTransactions /> },
  { path: "/self-service/sap/details/:id", element: <TransactionDetailsPage /> },
  { path: "/self-service/jjcc/details/:id", element: <SelfServiceDetailsPage /> },
  { path: "/self-service/configuration", element: <SelfServiceConfigurationPage /> },
];

import React, { useState } from "react";
import { CancelRounded } from "@mui/icons-material";
import { Alert, Link, Typography } from "@mui/material";

interface IDetailsErrorMessageProps {
  message: string;
  fullMessage: string | undefined;
}

const DetailsErrorMessage: React.FC<IDetailsErrorMessageProps> = ({ message, fullMessage }) => {
  const [isFullMessageVisible, setIsFullMessageVisible] = useState<boolean>(false);

  const setErrorMessage = () => {
    if (fullMessage) {
      setIsFullMessageVisible(!isFullMessageVisible);
    }
  };

  return (
    <Alert
      icon={<CancelRounded fontSize="inherit" sx={{ height: "24px", width: "24px" }} />}
      severity="error"
      sx={{
        marginBottom: "24px",
        borderRadius: "8px",
        backgroundColor: "#FFEAED",
        alignItems: "center",
        transition: "0.2s"
      }}
    >
      <Typography>
        {(!isFullMessageVisible && message) || (isFullMessageVisible && fullMessage)}{" "}
        <Link sx={{ ml: 2, fontSize: "13px", cursor: "pointer" }} underline="hover" onClick={setErrorMessage}>
          {isFullMessageVisible ? "Hide Error Details" : "View Error Details"}
        </Link>
      </Typography>
    </Alert>
  );
};

export default DetailsErrorMessage;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Line } from "react-chartjs-2";

import { AlertTypeEnum, useAlert } from "../../../providers/AlertProvider";
import { useUser } from "../../../providers/UserProvider";
import { postApi } from "../../../service";
import Loader from "../../common/loader/Loader";
import { optionsWithCurrency } from "./../constants";
import { purchaseOrderDataToChartData } from "./../helper";
import { countryDetails } from "../../common/constants/countries";

const PurchaseTrend = ({ formData, onSearch }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { addAlert } = useAlert();
  const dateFormat = countryDetails[user.countries[0]].dateFormat;

  const [resData, setResData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [duration, setDuration] = useState<string>("week");

  const dataFetch = async () => {
    try {
      setIsLoading(true);
      const res = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "OrderInsightTrendByValue",
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        countries: user.countries,
        ...formData,
      });

      const { data, status, message, duration } = res.data;

      if (!status) {
        setResData({});
        if (formData.duration !== "1 Month") addAlert({ message, type: AlertTypeEnum.ERROR });
      } else {
        data?.length && setResData(data);
        setDuration(duration);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    dataFetch();
  }, [onSearch]);

  const options = optionsWithCurrency(countryDetails[user.countries[0]].currency, resData, dateFormat);

  return (
    <Box sx={{ width: 1, position: "relative" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Typography
            variant="body1"
            sx={{
              transform: "rotate(-90deg)",
              transformOrigin: "0 0",
              position: "absolute",
              top: 200,
              left: 0,
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            {t("ORDER_VALUE")}
          </Typography>
          <Box sx={{ height: 330, ml: 4 }} flex={1}>
            <Line data={purchaseOrderDataToChartData(resData, dateFormat)} options={options as any} />
            <Typography variant="body1" align="center" sx={{ fontSize: "14px", fontWeight: 600 }}>
              {t("DURATION")}({t(`${duration}`)})
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PurchaseTrend;

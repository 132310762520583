/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AlertTypeEnum, useAlert } from "../../../providers/AlertProvider";
import { useUser } from "../../../providers/UserProvider";
import { postApi } from "../../../service";
import TrendInfoCard from "../../common/card/TrendInfoCard";
import Loader from "../../common/loader/Loader";

const AverageOrderQuantity = ({ onSearch, formData, loadDefaultValues }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { addAlert } = useAlert();

  const [value, setValue] = useState("0");
  const [isLoading, setIsLoading] = useState(true);

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      let res = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "CustomerInsightAverageQuantityByCustomer",
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        countries: user.countries,
        ...formData,
      });

      const { data, status } = res;
      if (loadDefaultValues(res)) {
        return;
      }
      if (!status) {
        if (formData.duration !== "1 Month")
          addAlert({ message: "Error in getting insights", type: AlertTypeEnum.ERROR });
        setValue("0");
      } else {
        const disp = (data.data.totalQuantity / data.data.noOfDocuments).toFixed(2).toString();
        setValue(disp === "NaN" ? "0" : Math.round(parseFloat(disp)).toString());
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleSearch();
  }, [onSearch]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <TrendInfoCard isTrendingUp={true} content={t("AVERAGE_ORDER_QUANTITY")} value={value} />
      )}
    </>
  );
};

export default AverageOrderQuantity;

import React from "react";
import { Container, Stack, Typography } from "@mui/material";

import SalesActivity from "../components/salesActivity/SalesActivity";
import CustomerDetails from "../components/customerDetails/CustomerDetails";

interface ICustomerReportProps {}

const CustomerReport: React.FC<ICustomerReportProps> = () => {
  return (
    <Container maxWidth="xl">
      <Stack direction="row" sx={{ mt: "42px", mb: 4 }}>
        <Typography variant="h1" sx={{ mr: "12px" }}>
          REPORTS
        </Typography>
      </Stack>
      <SalesActivity />
      <CustomerDetails />
    </Container>
  );
};

export default CustomerReport;

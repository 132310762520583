import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";

import App from "./App";
import theme from "./theme/Theme";
import { AuthProvider } from "./providers/AuthProvider";
import { UserProvider } from "./providers/UserProvider";
import { FormProvider } from "./providers/FormProvider";

import "./i18n";
import { EnvironmentProvider } from "./providers/EnvironmentProvider";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <UserProvider>
        <FormProvider>
          <EnvironmentProvider>
            <CssBaseline>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CssBaseline>
          </EnvironmentProvider>
        </FormProvider>
      </UserProvider>
    </AuthProvider>
  </ThemeProvider>
);

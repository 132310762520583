/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

import BasicTabs from "../common/BasicTabs";
import { StatusTypeEnum } from "../common/table/types";
import NavigateBack from "../navbar/NavigateBack";
import { handleKeysFromParams } from "../../helper";
import { postApi } from "../../service";
import { getIcon, handleNodeClick } from "./helper";
import { ITransactionDetailsElements } from "./types";
import { jjccFixedHeadCells, jjccFormList, jjccHeadCells, transactionDetailsElements } from "./constants";
import WorkFlow from "./workflow/WorkFlow";
import TransactionTab from "../transactionDetails/TransactionTab";
import { ITabData } from "../transactionDetails/TransactionDetails";
import VersionSelector from "../transactionDetails/VersionSelector";
import { useUser } from "../../providers/UserProvider";

interface ISelfServiceDetailsProps {}

const currentCom: ITransactionDetailsElements = transactionDetailsElements.jjcc;

const SelfServiceDetails: React.FC<ISelfServiceDetailsProps> = () => {
  const { search } = useLocation();
  const { user } = useUser();
  const navigate = useNavigate();
  const { gatewayOrderNumber } = handleKeysFromParams(search);

  const [tabIdx, setTabIdx] = useState<number>(0);
  const [versions, setVersions] = useState<any[]>([]);
  const [currentVersion, setCurrentVersion] = useState<number | null>(null);
  const [tabData, setTabData] = useState<ITabData[]>([]);
  const [status, setStatus] = useState<string>("START");

  useEffect(() => {
    (async () => {
      const res = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "SingleRecord",
        index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-inbound-order-details`,
        countries: user.countries || [],
        key: "gatewayOrderNumber",
        value: gatewayOrderNumber,
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
      });

      let orderStatus =
        res.data.data.result[0]._source.orderStatus?.toUpperCase() === "START" || "SUCCESS"
          ? StatusTypeEnum.SUCCESS
          : StatusTypeEnum.ERROR;
      setStatus(orderStatus);

      if (res.status < 300 && gatewayOrderNumber) {
        setTabData([
          res.data.data.result[0]._source.gatewayOrderNumber && {
            title: "inboundOrders",
            fixedHeadCells: jjccFixedHeadCells,
            flexibleHeadCells: jjccHeadCells,
            formList: jjccFormList,
            label: "Order Details",
            icon: getIcon(orderStatus),
            status: orderStatus,
            auditRequestData: {
              api_name: "DetailList",
              index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-inbound-order-detail-audit`,
              key: "gatewayOrderNumber",
              countries: user.countries || [],
              value: gatewayOrderNumber,
              size: 5,
              org: process.env.REACT_APP_ORG,
              sector: process.env.REACT_APP_SECTOR,
            },
            productRequestData: {
              api_name: "SingleRecord",
              countries: user.countries || [],
              index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-inbound-line-items`,
              key: "gatewayOrderNumber",
              value: gatewayOrderNumber,
              org: process.env.REACT_APP_ORG,
              sector: process.env.REACT_APP_SECTOR,
            },
          },
          {
            label: "Workflow",
            disabled: false,
            title: "workflow",
          },
        ]);
      }
    })();
  }, [search]);

  return (
    <>
      <Stack direction="row">
        <NavigateBack headerText={currentCom.headerText + gatewayOrderNumber} />
        <VersionSelector
          status={status}
          versions={versions}
          currentVersion={currentVersion}
          setCurrentVersion={setCurrentVersion}
        />
      </Stack>

      <BasicTabs
        data={tabData}
        components={tabData.map(({ title, ...rest }) => {
          if (title === "workflow") {
            return (
              <WorkFlow
                key={title}
                {...rest}
                order={{
                  api_name: "Workflow",
                  countries: user.countries || [],
                  index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-inbound-order-details`,
                  key: "gatewayOrderNumber",
                  value: gatewayOrderNumber,
                }}
                onNodeClick={(data: any) =>
                  Number(data.number) && setTabIdx(handleNodeClick({ ...data, navigate, search }))
                }
              />
            );
          }

          return (
            <>
              <TransactionTab
                key={title}
                {...rest}
                title={title}
                versions={versions}
                setVersions={setVersions}
                currentVersion={currentVersion}
                setCurrentVersion={setCurrentVersion}
              />
            </>
          );
        })}
        defaultTab={0}
        currentTab={tabIdx}
        setCurrentTab={(idx) => setTabIdx(idx)}
      />
    </>
  );
};

export default SelfServiceDetails;

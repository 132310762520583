import React from "react";
import { Box, Button, ButtonGroup, Stack, styled, TablePagination, useTheme } from "@mui/material";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";

import { rowsPerPageOptions } from "../../transactions/constants";
interface ITFooterProps {
  page?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  size?: number;
  setSize?: React.Dispatch<React.SetStateAction<number>>;
  count?: number;
}

const CustomIconButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2px 8px",
  borderColor: "#888B8D !important",
  "&:hover": {
    backgroundColor: "#C6C6C6",
  },
  "& span": {
    margin: 0,
  },
}));

const TFooter: React.FC<ITFooterProps> = ({
  page = 1,
  setPage = () => {},
  size = 10,
  setSize = () => {},
  count = 0,
}) => {
  const theme = useTheme();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, 1);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, Math.max(0, Math.ceil(count / size) - 1));
  };

  return (
    <Stack direction="row" sx={{ alignItems: "center" }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        colSpan={5}
        count={count}
        rowsPerPage={size}
        page={page - 1}
        SelectProps={{ inputProps: { "aria-label": "Show" } }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ style: { display: "none" } }}
        backIconButtonProps={{ style: { display: "none" } }}
      />
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          sx={{ overflow: "hidden", borderRadius: "20px" }}
        >
          <CustomIconButton
            startIcon={
              theme.direction === "rtl" ? (
                <LastPage color={page === 1 ? "disabled" : "primary"} />
              ) : (
                <FirstPage color={page === 1 ? "disabled" : "primary"} />
              )
            }
            onClick={handleFirstPageButtonClick}
            disabled={page === 1}
            aria-label="first page"
          ></CustomIconButton>

          <CustomIconButton
            startIcon={
              theme.direction === "rtl" ? (
                <KeyboardArrowRight color={page === 1 ? "disabled" : "primary"} />
              ) : (
                <KeyboardArrowLeft color={page === 1 ? "disabled" : "primary"} />
              )
            }
            onClick={handleBackButtonClick}
            disabled={page === 1}
            aria-label="previous page"
          ></CustomIconButton>
          <CustomIconButton
            startIcon={
              theme.direction === "rtl" ? (
                <KeyboardArrowLeft color={page >= Math.ceil(count / size) - 1 ? "disabled" : "primary"} />
              ) : (
                <KeyboardArrowRight color={page >= Math.ceil(count / size) - 1 ? "disabled" : "primary"} />
              )
            }
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / size) - 1}
            aria-label="next page"
          ></CustomIconButton>
          <CustomIconButton
            startIcon={
              theme.direction === "rtl" ? (
                <FirstPage color={page >= Math.ceil(count / size) - 1 ? "disabled" : "primary"} />
              ) : (
                <LastPage color={page >= Math.ceil(count / size) - 1 ? "disabled" : "primary"} />
              )
            }
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / size) - 1}
            aria-label="last page"
          ></CustomIconButton>
        </ButtonGroup>
      </Box>
    </Stack>
  );
};

export default TFooter;

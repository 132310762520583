import { DayOption, DayType } from "../ordersOverview/types";

export const dayOptions = [
  { value: DayType.TODAY, label: DayOption.TODAY },
  { value: DayType.SEVEN, label: DayOption.SEVEN },
  { value: DayType.THIRTY, label: DayOption.THIRTY },
  { value: DayType.THREE_MONTHS, label: DayOption.THREE_MONTHS },
  { value: DayType.SIX_MONTHS, label: DayOption.MONTHLY },
];

export const tabData = [{ label: "Most Sale" }, { label: "Least Sale" }];

export const options: any = {
  plugins: {
    legend: { position: "right", align: "end" },
    tooltip: {
      callbacks: {
        label: (tooltipItem: any) => {
          return `$${tooltipItem.formattedValue}`;
        },
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { display: false },
    },

    y: {
      min: 0,
      // max: 100,
      ticks: {
        // stepSize: 10000,
        callback: (value: string) => {
          return "$" + parseInt(value) / 1000 + "k";
        },
      },
    },
  },
};

export const data = {
  labels: ["Email1", "Email2", "Email3", "Email4", "Email5"],
  datasets: [
    {
      label: "This Month Sale",
      data: [43000, 46019, 41022, 39002, 42000],
      backgroundColor: "#00B5E2",
      pointStyle: "circle",
      pointBorderColor: "rgb(0, 0, 1)",
    },
    {
      label: "last Month Sales",
      data: [44000, 47019, 46022, 34002, 40000],
      backgroundColor: "#753BBD",
      pointStyle: "circle",
      pointBorderColor: "rgb(0, 0, 1)",
    },
  ],
};

import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { ArrowDropUp } from "@mui/icons-material";

import { IOrderAmountProps } from "./types";

const OrderAmount: React.FC<IOrderAmountProps> = ({ title, amount, growth }) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle1" sx={{ textTransform: "uppercase" }}>
        {title}
      </Typography>
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <Typography sx={{ mt: "4px" }} variant="h1">
          {amount}
        </Typography>
        {growth?.type && <ArrowDropUp sx={{ fontSize: "16px", color: "success.dark" }} />}
        <Typography variant="caption" sx={{ color: "success.dark" }}>
          {growth?.value}
        </Typography>
      </Stack>
    </Box>
  );
};

export default OrderAmount;

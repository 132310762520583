import { Bar, Line, Pie } from "react-chartjs-2";

export enum ChartTypeEnum {
  LINE = "line",
  BAR = "bar",
  PIE = "pie",
}

export const chartComponents = {
  [ChartTypeEnum.BAR]: Bar,
  [ChartTypeEnum.LINE]: Line,
  [ChartTypeEnum.PIE]: Pie,
};

export const chartColors = [
  "#00458b",
  "#e91800",
  "#229eae",
  "#000000",
  "#69d1f7",
  "#541781",
  "#c4bdb5",
  "#993300",
  "#CCCC99",
  "#666666",
  "#FFCC66",
  "#6699CC",
  "#663366",
  "#9999CC",
  "#CCCCCC",
  "#669999",
  "#CCCC66",
  "#CC6600",
  "#9999FF",
  "#0066CC",
  "#99CCCC",
  "#999999",
  "#FFCC00",
  "#009999",
  "#99CC33",
  "#FF9900",
  "#999966",
  "#66CCCC",
  "#339966",
  "#CCCC33",
  "#003f5c",
  "#665191",
  "#a05195",
  "#d45087",
  "#2f4b7c",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
  "#EF6F6C",
  "#465775",
  "#56E39F",
  "#59C9A5",
  "#5B6C5D",
  "#0A2342",
  "#2CA58D",
  "#84BC9C",
  "#CBA328",
  "#F46197",
  "#DBCFB0",
  "#545775",
];

import { TrendingUp, ErrorOutline } from "@mui/icons-material";

import { AlertTypeEnum } from "../../providers/AlertProvider";
import { DayOption, IInsightAlertProps } from "./types";
import { IChartData } from "../common/chart/types";

export const alertData: IInsightAlertProps[] = [
  {
    alertType: AlertTypeEnum.SUCCESS,
    message: "Highest number of order transactions was on 15 August 2022!",
  },
  {
    alertType: AlertTypeEnum.ERROR,
    message: "30% of order transactions failed last week",
  },
  {
    alertType: AlertTypeEnum.SUCCESS,
    message: "Product A has exceeded target sales for 3 months in a row!",
  },
];

// export const insightSetupData: IInsightAlertSetupProps[] = [
//   {
//     insights_name: "Product",
//     description: "[V] made the highest sales in the last 1 month",
//     valueQuery: "PRDCT001",
//   },
//   {
//     insights_name: "Customer",
//     description: "Customer [V] has decreased sales compared to the last month",
//     valueQuery: "CST0001",
//   },
//   {
//     insights_name: "Orders",
//     description: "There were [V] orders in the last 7 days",
//     valueQuery: "20",
//   },
// ];

export const alertTypeIcon = {
  [AlertTypeEnum.SUCCESS]: TrendingUp,
  [AlertTypeEnum.ERROR]: ErrorOutline,
};

export enum InsightTypeEnum {
  PRODUCT = "Product",
  SALES = "Sales",
  SYSTEM = "System",
}

export interface IInsightData {
  category?: string;
  date?: string;
  country?: string;
  description: string;
  insightType?: InsightTypeEnum;
  label: string;
  value?: any;
  data?: IChartData;
}

export interface IInsightSetupData {
  insightDescription: string;
  insightValue: string;
}

// export const insightData: IInsightData[] = [
//   {
//     date: new Date().toLocaleDateString('en-GB'),
//     country: "India",
//     insightDescription: "Highest Sales achieved on 01/06/2023. ",
//     insightType: InsightTypeEnum.SALES,
//     label: "Baxter Healthcare sales went up 12%",
//   },
//   {
//     date: new Date().toLocaleDateString('en-GB'),
//     country: "Australia",
//     insightDescription: "北京xxxxx有限公司 has achieved highest sales this month!!",
//     insightType: InsightTypeEnum.SALES,
//     label: "Baxter Healthcare sales went up 12%",
//   },
//   {
//     date: new Date().toLocaleDateString('en-GB'),
//     country: "China",
//     insightDescription: "Product A is Top selling product for this week!!!",
//     insightType: InsightTypeEnum.PRODUCT,
//     label: "Baxter Healthcare sales went up 12%",
//   },
// ];

// export const insightTypes = {
//   SALES: { name: "Sales" },
//   PRODUCT: { name: t("PRODUCT") },
//   SYSTEM: { name: "System" },
//   CUSTOMER: { name: "Customer" },
// };

export const dayOptions = [
  { value: "day", label: DayOption.DAILY },
  { value: "month", label: DayOption.MONTHLY },
];

export const searchPlaceHolder: String = "Search By Account ID or Account Name";

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";

import { useAuth } from "../providers/AuthProvider";
import { useUser } from "../providers/UserProvider";
import { postApi, postApiUserInfo } from "../service";
import { countryDetailsWithNames } from "../components/common/constants/countries";
import AuthorizationError from "./AuthorizationError";

interface ILocalAuth {
  access_token: string;
  refresh_token: string;
  expires_in: Date;
  email: string;
  name: string;
  role: string;
  code: string;
  soldToAccountsDHP: {
    accountId: string;
    accountName: string;
    customerGroup: string;
  }[];
  country: string;
}

const Authentication = () => {
  const { search } = useLocation();
  const { setAuth } = useAuth();
  const { setUser } = useUser();
  const [loading, setLoading] = useState<boolean>(true);
  const [authError, setAuthError] = useState<boolean>(false);

  useEffect(() => {
    (async () => await setAuthentication())();
  }, []);

  const setAuthentication = async () => {
    let code = "";
    if (search.includes("code=")) {
      code = search.substring(1).replace("code=", "");
    }

    if (code) {
      await getFetchData();
      window.location.href = window.location.origin;
    } else {
      setAuthError(true);
      setLoading(false);
    }
  };

  const getFetchData = async () => {
    let code = "";
    if (search.includes("code=")) {
      code = search.substring(1).replace("code=", "");
    }
    if (code === "") {
      return;
    }
    const requestBody = {
      api_name: "UserInfo",
      code: code,
      org: process.env.REACT_APP_ORG,
      sector: process.env.REACT_APP_SECTOR,
      env: process.env.REACT_APP_JJCC_ENV,
    };
    const res = await postApiUserInfo(`${process.env.REACT_APP_POST}`, requestBody);
    if (!res?.data?.data || res.data.data.error) return;

    const localAuth = res.data.data;
    updateCustomerMasterData(localAuth.soldToAccountsDHP);
    localStorage.setItem("auth", JSON.stringify(localAuth));
  };

  const updateCustomerMasterData = (soldToAccountsDHP) => {
    const requestBody = {
      api_name: "UpdateMasterSoldTos",
      org: process.env.REACT_APP_ORG,
      sector: process.env.REACT_APP_SECTOR,
      soldToAccountsDHP: soldToAccountsDHP,
    };
    postApi(`${process.env.REACT_APP_POST}`, requestBody);
  };
  return <>{loading ? <></> : authError ? <AuthorizationError /> : <Skeleton variant="rectangular" />}</>;
};

export default Authentication;

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';


import { chartColors } from "../common/chart/constants";

dayjs.extend(utc)
export const productQuantityDataToChartData = (data, dateFormat, key: string) => {
  return {
    labels: data[0].points.map(({ startDate }) => dayjs.utc(startDate).format(dateFormat)),
    datasets: data.map(({ points, productName }, index) => ({
      label: productName,
      data: points.map((point) => Math.round(point?.[key])),
      fill: false,
      borderWidth: 3,
      borderColor: chartColors[index],
      lineTension: 0.1,
      pointBackgroundColor: "#0000",
      backgroundColor: "#0000",
    })),
  };
};

export const purchaseOrderDataToChartData = (
  data: { startDate: string; endDate: string; value: number }[],
  dateFormat
) => ({
  labels: data.map(({ startDate }) => dayjs.utc(startDate).format(dateFormat)),
  datasets: [
    {
      label: "Order",
      data: data.map(({ value }) => value),
      fill: false,
      borderWidth: 3,
      borderColor: chartColors[0],
      lineTension: 0.1,
      pointBackgroundColor: "#0000",
      backgroundColor: "#0000",
    },
  ],
});

import { Handle, Position } from 'reactflow';
import { getIcon } from '../helper';

const CustomNode = ({ data } : any) => {
  let label = 'SAP Order No. created'
  if (data.type === 'delivery') {
    label = 'Delivery No. created'
  } else if (data.type === 'invoice') {
    label = 'Invoice No. created'
  } else if (data.type === 'hybris') {
    label = 'JJCC Order No. created'
  }
  if (data.status === 'ERROR') {
    label = "Error creating " + label.replace('created', '')
  }

  return (
    <div className={"custom-node" + (data.status === "ERROR" ? " error" : " ")}>
      <Handle type="target" position={Position.Left} style={data.type === 'hybris' ? {display: "none"} : {}} />
      <div className="container">
        <div className="status">{getIcon(data.status)}</div>
        <div className="message">
          <div className={"order-created" + (data.status === "ERROR" ? " error" : " ")}>{label}</div>
          <div className="order-number">{data.status === "ERROR" ? data.number : data.number === "hybris" ? "This order was created through SAP" : "#" + data.number}</div>
        </div>
      </div>
      <Handle type="source" position={Position.Right} />
    </div>
  )
}

export default CustomNode
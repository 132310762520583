import { DayOption, DayType, EOverallReportsConfig } from "./types";

export const dayOptions = [
  { value: DayType.TODAY, label: DayOption.TODAY },
  { value: DayType.WEEK, label: DayOption.WEEKLY },
  { value: DayType.THREE_MONTHS, label: DayOption.THREE_MONTHS },
  { value: DayType.MONTH, label: DayOption.MONTHLY },
];

export const tabData = [{ label: "Sales" }, { label: "Transaction" }];

export const lineOptions: any = {
  plugins: {
    legend: { display: false },
    tooltip: {
      callbacks: {
        label: (tooltipItem: any) => {
          return `$${tooltipItem.formattedValue}`;
        },
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { display: false },
    },
    y: {
      min: 0,
      ticks: { callback: (value: string) => "$" + parseInt(value) / 1000 + "k" },
    },
  },
};

export const lineDataSet = {
  fill: true,
  borderColor: "#1E22AA",
  pointBackgroundColor: "#1E22AA",
  borderWidth: 1,
  lineTension: 0.4,
};

export const barOptions: any = {
  plugins: {
    legend: { position: "right", align: "end" },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: { grid: { display: false }, stacked: true },
    y: { stacked: true },
  },
  onClick: (e: any, ele: any) => {
    const checkDataSetIndex = ele[0]?.datasetIndex.toString();
    const checkIndex = ele[0]?.index.toString();

    if (typeof checkDataSetIndex === "string" && typeof checkIndex === "string") {
    }
  },
};

export const barDataSet = {
  pointStyle: "circle",
  pointBorderColor: "rgb(0, 0, 1)",
};

export const overallReportsConfig = {
  [EOverallReportsConfig.totalSales]: "total this ",
  [EOverallReportsConfig.orderCount]: "total this ",
  [EOverallReportsConfig.invoiceCount]: "total this ",
  [EOverallReportsConfig.deliveryCount]: "total this ",
  [EOverallReportsConfig.orderSuccess]: "success",
  [EOverallReportsConfig.orderFail]: "fail",
  [EOverallReportsConfig.deliverySuccess]: "success",
  [EOverallReportsConfig.deliveryFail]: "fail",
  [EOverallReportsConfig.invoiceSuccess]: "success",
  [EOverallReportsConfig.invoiceFail]: "fail",
};

import React from "react";
import { Button } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { useForm } from "../../../providers/FormProvider";

interface IEditableButtonProps {
  handleFormSubmit: () => void;
}

const EditableButton: React.FC<IEditableButtonProps> = ({ handleFormSubmit }) => {
  const { isEditable, setIsEditable } = useForm();

  const handleSubmit = () => {
    handleFormSubmit();
    setIsEditable(!isEditable);
  };

  return isEditable ? (
    <Button
      onClick={handleSubmit}
      variant="buttonContained"
      sx={{ position: "absolute", top: "-56px", right: 0, "&:hover": { backgroundColor: "#025569" } }}
    >
      SUBMIT
    </Button>
  ) : (
    <Button
      onClick={() => setIsEditable(!isEditable)}
      startIcon={<EditOutlined sx={{ height: "24px", width: "24px" }} />}
      variant="buttonContained"
      sx={{ position: "absolute", top: "-56px", right: 0, "&:hover": { backgroundColor: "#025569" } }}
    >
      EDIT
    </Button>
  );
};

export default EditableButton;

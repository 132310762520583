import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { postApi } from "../../../service";
import { IProductWatchForm } from "../types";
import { useEnvironment } from "../../../providers/EnvironmentProvider";

interface ICriticalInterfaceProps {
  formDetails: UseFormReturn<IProductWatchForm, any, undefined>;
  isSearched: boolean;
}

interface ICriticalInterface {
  interface: string;
  total_transactions: number;
}

const CriticalInterface: FC<ICriticalInterfaceProps> = ({ formDetails, isSearched }) => {
  const { t } = useTranslation();
  const { getValues } = formDetails;
  const { environmentState } = useEnvironment();
  const theme = useTheme();

  const [criticalChartData, setCriticalChartData] = useState<ICriticalInterface[]>([]);

  useEffect(() => {
    (async () => {
      let res: { data: { data: ICriticalInterface[] }; status: number } = await postApi(
        `${process.env.REACT_APP_POST}`,
        {
          org: process.env.REACT_APP_ORG,
          sector: process.env.REACT_APP_SECTOR,
          api_name: "PerformanceInsightForCriticalInterface",
          ...getValues(),
          env: environmentState ? environmentState : process.env.PERFORMANCE_INDEX || null,
        }
      );

      try {
        setCriticalChartData(res.data.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [isSearched]);

  return (
    <TableContainer component={Paper} sx={{}}>
      <Table sx={{ display: "flex" }} aria-label="customized table">
        <TableHead>
          <TableRow sx={{ display: "flex", flexDirection: "column" }}>
            <TableCell sx={{ bgcolor: theme.palette.grey[300] }}>Interface</TableCell>
            <TableCell sx={{ bgcolor: theme.palette.grey[300] }}>Usage</TableCell>
          </TableRow>
        </TableHead>
        {criticalChartData.map((val) => (
          <TableBody key={val.interface}>
            <TableRow sx={{ display: "flex", flexDirection: "column" }}>
              <TableCell sx={{ minHeight: 57, borderRight: "1px solid lightgray" }}>{val.interface}</TableCell>
              <TableCell sx={{ minHeight: 57, borderRight: "1px solid lightgray" }}>{val.total_transactions}</TableCell>
            </TableRow>
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  );
};

export default CriticalInterface;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, styled, Tooltip, Stack, Button, Tab } from "@mui/material";

import { navigationData } from "./constants";
import { useUser } from "../../providers/UserProvider";

export interface INavItem {
  label: string;
  path: string;
}

interface NavigationDataType extends INavItem {
  subNavigation?: INavItem[];
}

interface INavItemsProps {}

interface INavItemProps extends NavigationDataType {
  pathIndex: number;
  setValue: (val: number) => void;
}

const NavItemContainer = styled((props: any) => <Tabs {...props} />)(({ theme }) => ({
  flex: 1,
  height: "32px",
  minHeight: "32px",
  "& span": { backgroundColor: theme.palette.secondary.main, borderRadius: "4px", zIndex: -1 },
}));

const NavItem = styled(({ path, subNavigation, pathIndex, setValue, ...rest }: INavItemProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleNavigation = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  useEffect(() => {
    const currentPathName: string = pathname;
    const matches: RegExpMatchArray | null = currentPathName.match(new RegExp(path));

    if (matches && matches[0] === path) {
      setValue(pathIndex);
    }
  }, [path, pathIndex, pathname, setValue]);

  return (
    <>
      {subNavigation ? (
        <Tooltip
          open={open}
          onClick={() => setOpen(!open)}
          onClose={() => setOpen(false)}
          leaveDelay={150}
          placement="bottom-start"
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                color: "red",
                backgroundColor: "white",
                boxShadow: "0px 4px 12px #00000033",
                padding: 0,
                minWidth: "200px",
                marginTop: "24px !important",
                borderRadius: "8px",
                overflow: "hidden",
              },
            },
          }}
          title={
            <Stack>
              {subNavigation.map(({ label, path }) => {
                return (
                  <Button
                    key={label}
                    variant="customButton"
                    onClick={() => {
                      handleNavigation(path);
                      setValue(pathIndex);
                    }}
                  >
                    {label}
                  </Button>
                );
              })}
            </Stack>
          }
        >
          <Tab disableRipple {...rest} />
        </Tooltip>
      ) : (
        <Tab
          onClick={() => {
            path && navigate(path);
            setValue(pathIndex);
          }}
          disableRipple
          {...rest}
        />
      )}
    </>
  );
})(({ theme }) => ({
  fontWeight: 600,
  lineHeight: "18px",
  fontSize: "14px",
  fontFamily: "'JJCircularMedium','Helvetica', 'Arial', sans-serif",
  color: theme.palette.text.secondary,
  textTransform: "capitalize",
  padding: 0,
  minWidth: 0,
  margin: "0 20px",
  minHeight: "32px",
  backgroundColor: "transparent",
  "&:hover": { color: theme.palette.text.primary },
  "&.Mui-selected": { color: theme.palette.text.primary },
}));

const NavItems: React.FC<INavItemsProps> = () => {
  const { user } = useUser();

  const [value, setValue] = useState(0);

  return (
    <NavItemContainer value={value} centered>
      {navigationData
        .filter(({ expect }) => !expect?.some((e) => e === user.countries[0]))
        .map(({ label, path, subNavigation }, pathIndex) => {
          return (
            <NavItem
              key={label}
              label={label}
              path={path}
              subNavigation={subNavigation}
              pathIndex={pathIndex}
              setValue={setValue}
            />
          );
        })}
    </NavItemContainer>
  );
};

export default NavItems;

import { FC, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  useTheme,
} from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { btnPrimary } from "../../insightsv2/constants";
import { postApi } from "../../../service";
import { IProductWatchForm } from "../types";
import TrendInfoCard from "../../common/card/TrendInfoCard";
import { useEnvironment } from "../../../providers/EnvironmentProvider";

interface IErrorCodeAnalyticsTableProps {
  isSearched: boolean;
  formDetails: UseFormReturn<IProductWatchForm, any, undefined>;
  formData: IProductWatchForm;
  showCriticalInterface: boolean;
}

const ErrorCodeAnalyticsTable: FC<IErrorCodeAnalyticsTableProps> = ({
  isSearched,
  formDetails,
  formData,
  showCriticalInterface,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getValues } = formDetails;
  const { environmentState } = useEnvironment();

  const [resTableData, setResTableData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAllViewActive, setIsAllViewActive] = useState<boolean>(false);
  const [totalStatement, setTotalStatement] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const val = getValues();

      if (formData.interface) {
        let res: { data: { data: { totalStatement: string; comparison: any[] } }; status: number } = await postApi(
          `${process.env.REACT_APP_POST}`,
          {
            org: "hmd-jjcc-nonprod-qa",
            sector: "medical",
            api_name: "PerformanceInsightForErrorCodeAnalysisInsight",
            ...val,
            env: environmentState ? environmentState : process.env.PERFORMANCE_INDEX || null,
          }
        );

        try {
          setTotalStatement(res.data.data.totalStatement);
          setResTableData(res.data.data.comparison);
        } catch (err) {
          console.error(err);
        }
      } else {
        let res: { data: { data: any[] }; status: number } = await postApi(`${process.env.REACT_APP_POST}`, {
          org: "hmd-jjcc-nonprod-qa",
          sector: "medical",
          api_name: "PerformanceInsightForErrorCodeAnalysisInsight",
          ...val,
          env: environmentState ? environmentState : process.env.PERFORMANCE_INDEX || null,
        });

        try {
          setResTableData(res.data.data);
        } catch (err) {
          console.error(err);
        }
      }

      setIsLoading(false);
    })();
  }, [isSearched]);

  return (
    <>
      {showCriticalInterface ? (
        <>
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ bgcolor: theme.palette.grey[300] }}>Error Details</TableCell>
                  <TableCell sx={{ bgcolor: theme.palette.grey[300], border: "1px solid lightgray" }}>
                    Interface
                  </TableCell>
                  <TableCell sx={{ bgcolor: theme.palette.grey[300] }}>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resTableData
                  .slice(0, !isAllViewActive && resTableData.length >= 10 ? 10 : undefined)
                  .map((error, key) => (
                    <TableRow key={key}>
                      <TableCell>{error.message}</TableCell>
                      <TableCell sx={{ border: "1px solid lightgray" }}>{error.interface}</TableCell>
                      <TableCell>{error.transactionCount}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!isAllViewActive && resTableData.length >= 10 && (
            <Button variant="contained" sx={{ ...btnPrimary, width: 1 }} onClick={() => setIsAllViewActive(true)}>
              View More
            </Button>
          )}
        </>
      ) : (
        <>
          {totalStatement && (
            <TrendInfoCard
              isTrendingUp={totalStatement.includes("decreased") ? false : true}
              content={totalStatement}
            />
          )}

          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ bgcolor: theme.palette.grey[300] }}>message</TableCell>
                  <TableCell sx={{ bgcolor: theme.palette.grey[300], border: "1px solid lightgray" }}>
                    current
                  </TableCell>
                  <TableCell sx={{ bgcolor: theme.palette.grey[300] }}>prev</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resTableData
                  .slice(0, !isAllViewActive && resTableData.length >= 10 ? 10 : undefined)
                  .map(({ message, current, prev }, key) => (
                    <TableRow key={key}>
                      <TableCell>{message}</TableCell>
                      <TableCell sx={{ border: "1px solid lightgray" }}>{current}</TableCell>
                      <TableCell>{prev}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default ErrorCodeAnalyticsTable;

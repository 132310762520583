import { Box, Container, Typography } from "@mui/material";
//import React from "react";

//const arr = ["Contact Us", "Privacy Policy", "Legal Notice", "Terms & Conditions"];

// const CustomButton = styled((props: { children: React.ReactNode; index: number }) => (
//   <Button {...props} sx={{ paddingLeft: props.index === 0 ? 0 : 1 }}>
//     {props.children}
//   </Button>
// ))(({ theme }) => ({
//   fontWeight: "bold",
//   lineHeight: "14px",
//   fontSize: "11px",
//   fontFamily: "'JJCircularBold','Helvetica', 'Arial', sans-serif",
//   color: theme.palette.text.secondary,
//   textTransform: "capitalize",
//   borderRadius: 0,
//   borderColor: `${theme.palette.text.secondary} !important`,
//   paddingTop: 0,
//   paddingBottom: 0,
//   "&:hover": {
//     color: theme.palette.text.secondary,
//     backgroundColor: "transparent",
//   },
// }));

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "#fff", px: "30px", py: "40px", mt: 3 }}>
      <Container maxWidth="sm" sx={{ ml: 0 }}>
        {/* <ButtonGroup variant="text" aria-label="text button group">
          {arr.map((e: string, i: number) => (
            <CustomButton key={i} index={i}>
              {e}
            </CustomButton>
          ))}
        </ButtonGroup> */}
        <Typography variant="subtitle1" fontWeight={600} fontSize={11} color={"#888B8D"} sx={{ mt: 1 }}>
          This site is published by Johnson & Johnson Medical Pty Ltd, which is solely responsible for its contents. It
          is intended for visitors from Australia only.
        </Typography>
        <Typography variant="subtitle1" fontWeight={600} fontSize={11} color={"#888B8D"} sx={{ mt: 2 }}>
          Last Updated: 17-06-2019. © Johnson & Johnson Medical Pty Ltd 2019. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

import React from "react";
import { Stack, Typography } from "@mui/material";

import { IBasicHeaderProps } from "./types";

const BasicHeader: React.FC<IBasicHeaderProps> = ({
  title,
  customStyle = {},
  children,
  leftElements,
  childGap = 3,
}) => {
  return (
    <Stack direction="row" sx={customStyle} justifyContent="space-between">
      <Stack direction="row" gap={3}>
        <Typography sx={{ flex: 1 }} variant="h1" textTransform={"capitalize"}>
          {title}
        </Typography>
        {leftElements}
      </Stack>

      <Stack direction="row" gap={childGap}>
        {children}
      </Stack>
    </Stack>
  );
};

export default BasicHeader;

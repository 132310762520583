/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ReactFlow from "reactflow";
import "reactflow/dist/style.css";

import DelimitBox from "../../common/DelimitBox";
import CustomNode from "./CustomNode";
import "./CustomNode.css";
import { Box, Typography } from "@mui/material";
import { postApi } from "../../../service";

interface IWorkFlowProps {
  order: any;
  onNodeClick: (type: string) => void;
}

const WorkFlow = ({ order, onNodeClick }: IWorkFlowProps) => {
  const nodeTypes = { custom: CustomNode };
  const [fitView, setFitView] = useState<boolean>(false);
  const [edges, setEdges] = useState<any>([]);
  const [nodes, setNodes] = useState<any>([]);
  const loadWorkflow = async (orderNumber: any) => {
    const res = await postApi(`${process.env.REACT_APP_POST}`, order);

    const wf = res.data.data;
    let edges: any[] = [];
    let nodes: any[] = [];
    if (wf) {
      let hybrisOrderNumber = wf["inbound"]["hybrisOrderNumber"] || "hybris";
      let sapOrderNumber = wf["outbound"]["sapOrderNumber"];
      nodes.push({
        id: hybrisOrderNumber,
        type: "custom",
        data: { type: "hybris", number: hybrisOrderNumber, status: wf["inbound"]["status"] },
        position: { x: 0, y: 0 },
      });
      edges.push({
        id: hybrisOrderNumber + "-" + sapOrderNumber,
        source: hybrisOrderNumber,
        target: sapOrderNumber,
      });
      nodes.push({
        id: sapOrderNumber,
        type: "custom",
        data: { type: "order", number: sapOrderNumber, status: wf["outbound"]["status"] },
        position: { x: 130, y: 0 },
      });
      let deliveries = wf["outbound"]["deliveries"];
      let outboundInvoices = wf["outbound"]["invoices"];
      if (deliveries && deliveries.length > 0) {
        deliveries.forEach((d: any, i: any) => {
          let deliveryNum = d["deliveryNumber"];
          nodes.push({
            id: deliveryNum,
            type: "custom",
            data: { type: "delivery", number: deliveryNum, status: d["status"] },
            position: { x: 260, y: i * 100 },
          });
          edges.push({
            id: sapOrderNumber + "-" + deliveryNum,
            source: sapOrderNumber,
            target: deliveryNum,
          });
          let invoices = d["invoices"];
          if (invoices && invoices.length > 0) {
            invoices.forEach((inv: any, i: any) => {
              let invoiceNum = inv["invoiceNumber"];
              nodes.push({
                id: invoiceNum,
                type: "custom",
                data: { type: "invoice", number: invoiceNum, status: inv["status"] },
                position: { x: 390, y: i * 100 },
              });
              edges.push({
                id: deliveryNum + "-" + invoiceNum,
                source: deliveryNum,
                target: invoiceNum,
              });
            });
          }
        });
      }

      if (outboundInvoices && outboundInvoices.length > 0) {
        outboundInvoices.forEach((inv: any, i: any) => {
          let invoiceNum = inv["invoiceDocumentNumber"];
          nodes.push({
            id: invoiceNum,
            type: "custom",
            data: { type: "invoice", number: invoiceNum, status: inv["status"] },
            position: { x: 390, y: i * 100 },
          });
          edges.push({
            id: sapOrderNumber + "-" + invoiceNum,
            source: sapOrderNumber,
            target: invoiceNum,
          });
        });
      }
      setNodes(nodes);
      setEdges(edges);
      setFitView(true);
    }
  };

  useEffect(() => {
    loadWorkflow(order);
  }, [order]);

  return (
    <>
      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: 2,
          boxShadow: "0px 4px 12px #00000033",
          flex: 1,
          p: "20px",
          mb: "6px",
        }}
      >
        <Typography>
          <b>Note:</b> 1. Click on JJCC/SAP/Delivery/Invoice number present in the flow diagram it will lead to the
          respective page. <br></br>
          2. Workflow diagram can be minimized and maximized by scrolling.<br></br>
          3. The Entire flow diagram can be relocated inside the screen by dragging.
        </Typography>
      </Box>
      <DelimitBox customStyle={{ flex: 1 }}>
        <div style={{ height: "600px", width: "100%" }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            fitView={fitView}
            draggable={false}
            nodesDraggable={false}
            onNodeClick={(event, node) => onNodeClick(node.data)}
          />
        </div>
      </DelimitBox>
    </>
  );
};

export default WorkFlow;

import React from "react";
import { Toolbar } from "@mui/material";

import Breadcrumb from "../components/common/breadcrumb/Breadcrumb";
import Footer from "../components/footer/Footer";
import NavBar from "../components/navbar/NavBar";
import { AlertProvider } from "../providers/AlertProvider";
import { IChildrenProps } from "../components/common/types";

export const PrivateRouteWrapper: React.FC<IChildrenProps> = ({ children }) => {
  return (
    <>
      <NavBar />
      <Toolbar sx={{ minHeight: "76px !important" }} />

      <AlertProvider>
        <Breadcrumb />
        {children}
      </AlertProvider>
      <Footer />
    </>
  );
};

export default PrivateRouteWrapper;
